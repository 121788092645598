import React, { type FC } from 'react'
import { Spinner } from 'react-bootstrap'

const Loader: FC = () => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center py-5 my-5">
      <Spinner variant="primary" />
    </div>
  )
}

export default Loader
