import { type ActionInterface } from '../IAction';
import {
    TOGGLE_SIDE_BAR
} from './actions';

export interface IMobileMenuInitialState {
    collapse: boolean
}

const initialState = {
    collapse: false
};

const reducer = (state = initialState, action: ActionInterface<unknown>): IMobileMenuInitialState => {
    switch (action.type) {
        case TOGGLE_SIDE_BAR:
            return {
                collapse: !state.collapse
            };
        default:
            return state;
    }
};

export default reducer;
