import React, { useState, type FC, useEffect } from 'react'
import styles from '../dashboard.module.css'
import { combineClassName, convertTime } from '../../../util/utilMethods'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBookings } from '../../../redux/bookings/actions'
import type { IBookingsState } from '../../../interface/bookings'
import moment from 'moment'
import { statusColor } from '../../Bookings/Components/BookingStatus'
import Loader from '../../../Components/Loader'

export enum Headings {
  all = 'all',
  swimming_pool = 'swimming_pool',
  football_pitch = 'football_pitch',
  dance = 'dance',
  restaurant = 'restaurant',
  nail_saloon = 'nail_saloon',
  games_arcade = 'games_arcade',
}

const RecentBookings: FC = () => {
  const dispatch = useDispatch()

  const { data: bookings, loading } = useSelector(
    (state: { bookings: IBookingsState }) => state.bookings
  )

  const [tab, setTab] = useState<Headings>(Headings.all)

  useEffect(() => {
    if (tab === Headings.all) {
      dispatch(getAllBookings())
    } else {
      dispatch(getAllBookings({ searchName: 'activity', search: tab }))
    }
  }, [tab])
  return (
    <div className={styles.bookings}>
      <div
        className={combineClassName(
          'd-flex justify-content-between gap-1',
          styles.bookings_headings
        )}
      >
        <h3>Recent Bookings</h3>
        <p
          className={combineClassName(
            'align-self-end rounded',
            styles.bookings_subheading,
            tab === Headings.all ? styles.bookings_subheading_active : ''
          )}
          onClick={() => {
            setTab(Headings.all)
          }}
        >
          All
        </p>
        <p
          className={combineClassName(
            'align-self-end rounded',
            styles.bookings_subheading,
            tab === Headings.swimming_pool
              ? styles.bookings_subheading_active
              : ''
          )}
          onClick={() => {
            setTab(Headings.swimming_pool)
          }}
        >
          Swimming
        </p>
        <p
          className={combineClassName(
            'align-self-end rounded',
            styles.bookings_subheading,
            tab === Headings.football_pitch
              ? styles.bookings_subheading_active
              : ''
          )}
          onClick={() => {
            setTab(Headings.football_pitch)
          }}
        >
          Football
        </p>
        <p
          className={combineClassName(
            'align-self-end rounded',
            styles.bookings_subheading,
            tab === Headings.dance ? styles.bookings_subheading_active : ''
          )}
          onClick={() => {
            setTab(Headings.dance)
          }}
        >
          Dance
        </p>
        <p
          className={combineClassName(
            'align-self-end rounded',
            styles.bookings_subheading,
            tab === Headings.restaurant ? styles.bookings_subheading_active : ''
          )}
          onClick={() => {
            setTab(Headings.restaurant)
          }}
        >
          Restaurant
        </p>
        <p
          className={combineClassName(
            'align-self-end rounded',
            styles.bookings_subheading,
            tab === Headings.nail_saloon
              ? styles.bookings_subheading_active
              : ''
          )}
          onClick={() => {
            setTab(Headings.nail_saloon)
          }}
        >
          Nail / bar
        </p>
        <p
          className={combineClassName(
            'align-self-end rounded',
            styles.bookings_subheading,
            tab === Headings.games_arcade
              ? styles.bookings_subheading_active
              : ''
          )}
          onClick={() => {
            setTab(Headings.games_arcade)
          }}
        >
          Games
        </p>
      </div>
      <Table responsive borderless className={styles.bookings_table}>
        <thead>
          <tr>
            <th className={styles.bookings_table_head}>Package</th>
            <th className={styles.bookings_table_head}>Customer name</th>
            <th className={styles.bookings_table_head}>Phone number</th>
            <th className={styles.bookings_table_head}>Date picked</th>
            <th className={styles.bookings_table_head}>Time picked</th>
            <th className={styles.bookings_table_head}>Status</th>
          </tr>
        </thead>
        {loading ? (
          <div className="d-flex justif-content-center align-items-center w-100 ps-5 ms-5">
            <Loader />
          </div>
        ) : (
          <tbody>
            {bookings?.slice(0, 5)?.map((item, i) => (
              <tr key={i} className="text-capitalize">
                <td>{item?.activity?.replaceAll('_', ' ')}</td>
                <td>
                  {item?.slot?.member?.firstName} {item?.slot?.member?.lastName}
                </td>
                <td>{item?.slot?.member?.phone ?? 'Not available'}</td>
                <td>
                  {moment(
                    item?.slot?.date?.split('-')?.reverse()?.join('-') ?? ''
                  ).format('DD MMM YYYY')}
                </td>
                <td>
                  {convertTime(item?.slot?.startTime)}{' '}
                  {item?.slot?.endTime ? (
                    <span>{` - ${convertTime(item?.slot?.endTime)}`}</span>
                  ) : null}
                </td>
                <td className="d-flex justify-content-center align-item-center">
                  <span
                    className={styles.table_status}
                    style={statusColor(item?.status)}
                  >
                    &#9679; {item?.status?.replaceAll('_', ' ')}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      <div className={styles.view_all}>
        <p>
          <Link to="/bookings">View All</Link>
        </p>
      </div>
    </div>
  )
}

export default RecentBookings
