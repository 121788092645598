import React, { useEffect, type FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Header from '../../Components/Header'
import Menu from '../../Components/Menu'
import styles from './main.module.css'
import * as storage from '../../util/localStorage'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../redux/currentUser/actions'
import ExportModal from '../../Components/ExportModal'
import { combineClassName } from '../../util/utilMethods'

const Main: FC = () => {
  const dispatch = useDispatch()

  const collapse = useSelector(
    (state: { sideBar: { collapse: boolean } }) => state.sideBar.collapse
  )

  useEffect(() => {
    dispatch(getCurrentUser())
  }, [])

  if (!storage.getItem('accessToken')) {
    return <Navigate to="/login" replace />
  }

  return (
    <>
      <ExportModal />
      <Header />
      <div className={styles.main_container}>
        <Menu />
        <div
          className={combineClassName(
            styles.outlet,
            collapse ? styles.collapsed_outlet : ''
          )}
        >
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default Main
