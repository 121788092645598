import React, { useState, type FC, useEffect } from 'react'
import styles from './dashboard.module.css'
import Icon from '../../Components/Icon'
import LineChart from './Components/LineChart'
import RecentBookings from './Components/RecentBookings'
import { Dropdown } from 'react-bootstrap'
import { combineClassName, thousandSeparator } from '../../util/utilMethods'
import { Link } from 'react-router-dom'
import type {
  IDashboardAnalytics,
  ITopBooking,
} from '../../interface/analytics'
import api from '../../network/api'
import millify from 'millify'
import { useSelector } from 'react-redux'
import type { IUser } from '../../interface/auth'
import { handleError } from '../../util/error'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const Dashboard: FC = () => {
  const user = useSelector(
    (state: { currentUser: { data: IUser } }) => state.currentUser.data
  )

  const [month, setMonth] = useState<string>(months[new Date().getMonth()])
  const [monthVal, setMonthVal] = useState<number>(new Date().getMonth() + 1)
  const [analytics, setAnalytics] = useState<IDashboardAnalytics>()
  const [topBooking, setTopBoking] = useState<ITopBooking[]>()

  const getAnalytics = async (): Promise<void> => {
    try {
      const resp = await api.getDashboardAnalytics()
      setAnalytics(resp.data)
    } catch (error: any) {
      handleError(error)
    }
  }

  const getTopBooking = async (): Promise<void> => {
    try {
      const resp = await api.getTopBooking({
        year: new Date().getFullYear(),
        month: monthVal,
      })
      setTopBoking(resp.data)
    } catch (error: any) {
      handleError(error)
    }
  }

  useEffect(() => {
    getAnalytics()
  }, [])

  useEffect(() => {
    getTopBooking()
  }, [monthVal])

  return (
    <main>
      <h2 className={styles.dashboard_header}>
        Welcome {user?.profile?.firstName} {user?.profile?.lastName}
      </h2>
      <section className={styles.dash_data_container}>
        <div className={styles.dash_data}>
          <div className={styles.dash_data_icon1}>
            <Icon name="total_members" />
          </div>
          <div>
            <h4 className={styles.dash_data_header}>
              {thousandSeparator(analytics?.totalMember ?? '')}
            </h4>
            <p>Total members</p>
            {/* <p className={styles.dash_data_percent}>
              +2.5% <Icon name="green_up_arrow" />
            </p> */}
          </div>
        </div>
        <div className={styles.dash_data}>
          <div className={styles.dash_data_icon2}>
            <Icon name="pending_bookings" />
          </div>
          <div>
            <h4 className={styles.dash_data_header}>
              {thousandSeparator(analytics?.totalPending ?? '')}
            </h4>
            <p>Pending bookings</p>
            {/* <p className={styles.dash_data_percent}>
              +2.5% <Icon name="green_up_arrow" />
            </p> */}
          </div>
        </div>
        <div className={styles.dash_data}>
          <div className={styles.dash_data_icon3}>
            <Icon name="total_guests" />
          </div>
          <div>
            <h4 className={styles.dash_data_header}>
              {thousandSeparator(analytics?.totalGuest ?? '')}
            </h4>
            <p>Total guests</p>
            {/* <p className={styles.dash_data_percent}>
              +2.5% <Icon name="green_up_arrow" />
            </p> */}
          </div>
        </div>
      </section>
      <LineChart />
      <section className={styles.bookings_container}>
        <RecentBookings />
        <div className={styles.top_bookings}>
          <div className={combineClassName(styles.chart_header, 'mb-4')}>
            <h6 className={styles.top_bookings_header}>Top bookings</h6>
            <Dropdown className={styles.chart_dropdown}>
              <Dropdown.Toggle variant="primary">{month}</Dropdown.Toggle>
              <Dropdown.Menu>
                {months.map((month, i) => (
                  <Dropdown.Item
                    key={i}
                    onClick={() => {
                      setMonth(month)
                      setMonthVal(i + 1)
                    }}
                  >
                    {month}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            {topBooking?.map((booking, index) => (
              <p
                className={combineClassName(
                  'border-bottom pb-3 text-capitalize',
                  styles.chart_header,
                  styles.top_bookings_items
                )}
                key={index}
              >
                <span>{booking?.activity?.replaceAll('_', ' ')}</span>
                <span>{millify(booking.count)}</span>
              </p>
            ))}
          </div>
          <div className={styles.view_all}>
            <p>
              <Link to="/bookings">View All</Link>
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Dashboard
