import React, { useState, type FC, type ChangeEvent } from 'react'
import { FloatingLabel, Modal, Form, Button } from 'react-bootstrap'
import styles from '../advert.module.css'
import { combineClassName } from '../../../util/utilMethods'
import FileUpload from '../../../Components/FileInput'
import SuccessModal from '../../../Components/SuccessModal'
import type { IAddAdvertData } from '../../../interface/adverts'
import { toast } from 'react-toastify'
import validator from 'validator'
import api from '../../../network/api'
import { handleError } from '../../../util/error'
import { useDispatch } from 'react-redux'
import { getAllAdverts } from '../../../redux/adverts/actions'

interface AddAdvertProps {
  addAdvert: boolean
  toggleAddAdvertModal: () => void
}

const initialState = {
  name: '',
  link: '',
  image: null,
}

const AddAdvertModal: FC<AddAdvertProps> = ({
  addAdvert,
  toggleAddAdvertModal,
}) => {
  const dispatch = useDispatch()

  const [success, setSuccess] = useState(false)
  const [advert, setAdvert] = useState<IAddAdvertData>(initialState)
  const [loading, setLoading] = useState(false)

  const toggleSuccess = (): void => {
    setSuccess(!success)
  }

  const closeSuccess = (): void => {
    dispatch(getAllAdverts())
    setSuccess(false)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setAdvert({ ...advert, [e.target.name]: e.target.value })
  }

  const validate = (): boolean =>
    !advert?.name || !validator.isURL(advert?.link) || !advert?.image || loading

  const handleSubmit = async (): Promise<void> => {
    setLoading(true)
    try {
      const formData = new FormData()

      formData.append('name', advert.name)
      formData.append('link', advert.link)
      formData.append('image', advert.image)

      await api.addAdvert(formData)

      toggleSuccess()
      setAdvert(initialState)
      toggleAddAdvertModal()
    } catch (error) {
      handleError(error)
    }
    setLoading(false)
  }

  return (
    <>
      <SuccessModal
        description="Advert added successfully"
        show={success}
        onHide={closeSuccess}
      />
      <Modal size="lg" centered show={addAdvert} onHide={toggleAddAdvertModal}>
        <Modal.Header className="border-bottom border-2 pb-0">
          <Modal.Title>
            <p className={styles.modal_title}>Advert details</p>
            <p className={combineClassName(styles.modal_subtitle)}>
              Please enter advert information below
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className={combineClassName(
              'd-flex justify-content-between gap-3 mt-3',
              styles.inputs_wrapper
            )}
          >
            <FloatingLabel label="Advert name" className="w-100">
              <Form.Control
                type="text"
                placeholder="Advert name"
                name="name"
                value={advert.name}
                onChange={handleChange}
              />
            </FloatingLabel>
            <FloatingLabel label="Link URL" className="w-100">
              <Form.Control
                type="text"
                placeholder="Link URL"
                name="link"
                value={advert.link}
                onChange={handleChange}
              />
            </FloatingLabel>
          </div>
          <div className="mt-4">
            <p>
              <strong>Image Dimensions</strong>
            </p>
            <p>Width: 496px / 17.50cm / 6.89in</p>
            <p>Height: 1185px / 41.80cm / 16.46in</p>
            <FileUpload
              descrption="Drop file to upload advert image"
              accept="*.jpeg, *.png or * only"
              fileName={advert?.image?.name}
              handleInputChange={(e) => {
                if (e?.target?.files) {
                  const file = e.target.files[0]
                  if (file?.size > 10 * 1024 * 1024) {
                    setAdvert({ ...advert, image: null })
                    toast.error('Image cannot be more than 10MB')
                  } else {
                    setAdvert({ ...advert, image: file })
                  }
                }
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between flex-wrap gap-1">
          <Button
            size="lg"
            variant="outline-secondary"
            className={styles.add_advert_btn}
            onClick={toggleAddAdvertModal}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            className={styles.add_advert_btn}
            onClick={(): any => {
              handleSubmit()
            }}
            disabled={validate()}
          >
            {loading ? 'Please wait...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddAdvertModal
