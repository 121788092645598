import React, { useState, type FC, type ChangeEvent } from 'react'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import styles from '../members.module.css'
import {
  combineClassName,
  phoneNumberValidation,
} from '../../../util/utilMethods'
import FileUpload from '../../../Components/FileInput'
import Icon from '../../../Components/Icon'
import SuccessModal from '../../../Components/SuccessModal'
import type { IAddMemeber } from '../../../interface/members'
import { toast } from 'react-toastify'
import validator from 'validator'
import api from '../../../network/api'
import { useDispatch } from 'react-redux'
import { getAllMembers } from '../../../redux/members/actions'
import { handleError } from '../../../util/error'

interface AddUserModalProps {
  addUser: boolean
  toggleAddUserModal: () => void
}

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  memberPackage: '',
  status: 'active',
  image: null,
  phone: '',
}

const AddUserModal: FC<AddUserModalProps> = ({
  addUser,
  toggleAddUserModal,
}) => {
  const dispatch = useDispatch()

  const [membersArray, setMembersArray] = useState<IAddMemeber[]>([])
  const [success, setSuccess] = useState(false)
  const [member, setMember] = useState<IAddMemeber>(initialState)
  const [loading, setLoading] = useState(false)

  const toggleSuccess = (): void => {
    setSuccess(!success)
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ): void => {
    let value = e.target.value
    if (e.target.name === 'phone') {
      value = phoneNumberValidation(value)
    }
    setMember({ ...member, [e.target.name]: value })
  }
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    setMember({ ...member, [e.target.name]: e.target.value })
  }

  const validate = (): boolean =>
    !member?.firstName ||
    !member?.lastName ||
    !validator.isEmail(member?.email) ||
    !member?.memberPackage ||
    !member?.phone ||
    !member?.image ||
    loading

  const addAnother = (): void => {
    if (!validate()) {
      const arr = [...membersArray]
      setMembersArray([...arr, member])
      setMember(initialState)
    } else {
      toast.error('Please crosscheck input fields')
    }
  }

  const deleteMember = (index: number): void => {
    const arr = [...membersArray]
    arr.splice(index, 1)
    setMembersArray(arr)
  }

  const editMember = (index: number): void => {
    const arr = [...membersArray]
    setMember(arr[index])
    deleteMember(index)
  }

  const onAddMembers = async (): Promise<void> => {
    setLoading(true)
    let arr = [...membersArray]
    if (!validate()) {
      arr = [...arr, member]
    }
    await Promise.all(
      arr.map(async (data) => {
        const formData = new FormData()

        formData.append('firstName', data?.firstName)
        formData.append('lastName', data?.lastName)
        formData.append('email', data?.email)
        formData.append('status', data?.status)
        formData.append('memberPackage', data?.memberPackage)
        formData.append('phone', data?.phone)
        data?.image && formData.append('image', data?.image)
        return api.addMembers(formData)
      })
    )
      .then((res) => {
        toggleAddUserModal()
        toggleSuccess()
        setMember(initialState)
        setMembersArray([])
      })
      .catch((error: any) => {
        setLoading(false)
        handleError(error)
      })
    setLoading(false)
  }

  return (
    <>
      <SuccessModal
        show={success}
        description="Successful"
        onHide={(): any => {
          toggleSuccess()
          dispatch(getAllMembers())
        }}
      />
      <Modal show={addUser} centered onHide={toggleAddUserModal} size="lg">
        <Modal.Header className="border-bottom border-2 pb-0">
          <Modal.Title>
            <p className={styles.modal_title}>Add user</p>
            <p className={combineClassName(styles.modal_subtitle)}>
              Please user information below
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {membersArray?.map((user, i) => (
              <div
                key={i}
                className={combineClassName(
                  'd-flex justify-content-between align-items-center p-3 mb-2',
                  styles.users_list
                )}
              >
                <p>
                  {`${user?.firstName} ${user?.lastName}`} | {user?.email} |{' '}
                  {user?.phone}
                </p>
                <div className="d-flex gap-2">
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      editMember(i)
                    }}
                  >
                    <Icon name="edit" />
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      deleteMember(i)
                    }}
                  >
                    <Icon name="red-trash" />
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div
            className={combineClassName(
              'd-flex justify-content-between gap-3 mt-3',
              styles.inputs_wrapper
            )}
          >
            <FloatingLabel label="First name" className="w-100">
              <Form.Control
                type="text"
                placeholder="First name"
                name="firstName"
                onChange={handleChange}
                value={member.firstName}
              />
            </FloatingLabel>
            <FloatingLabel label="Last name" className="w-100">
              <Form.Control
                type="text"
                placeholder="Last name"
                name="lastName"
                onChange={handleChange}
                value={member.lastName}
              />
            </FloatingLabel>
          </div>
          <div
            className={combineClassName(
              'd-flex justify-content-between gap-3 mt-4',
              styles.inputs_wrapper
            )}
          >
            <FloatingLabel label="Phone number" className="w-100">
              <Form.Control
                type="text"
                placeholder="Phone number"
                name="phone"
                onChange={handleChange}
                value={member.phone}
                maxLength={11}
              />
            </FloatingLabel>
            <Form.Select
              size="lg"
              name="memberPackage"
              value={member.memberPackage}
              onChange={handleSelectChange}
            >
              <option value="">Member package</option>
              <option value="guest">Guest</option>
              <option value="prime">Prime</option>
              <option value="basic">Basic</option>
              <option value="executive">Executive</option>
            </Form.Select>
          </div>
          <div className="mt-4">
            <FloatingLabel label="Email address" className="w-100">
              <Form.Control
                type="email"
                placeholder="Email address"
                name="email"
                onChange={handleChange}
                value={member.email}
              />
            </FloatingLabel>
          </div>
          <div className="mt-4">
            <FileUpload
              descrption="Drop file to upload user image"
              accept="*.jpeg, *.png or * only"
              fileName={member?.image?.name}
              handleInputChange={(e) => {
                if (e?.target?.files) {
                  const file = e.target.files[0]
                  const acceptedTypes = [
                    'image/png',
                    'image/jpeg',
                    'image/svg+xml',
                  ]
                  if (file?.size > 10 * 1024 * 1024) {
                    setMember({ ...member, image: null })
                    toast.error('Image cannot be more than 10MB')
                  } else if (!acceptedTypes?.includes(file?.type)) {
                    toast.error('Unsupported format please choose another file')
                  } else {
                    setMember({ ...member, image: file })
                  }
                }
              }}
            />
          </div>
          <div className="mt-4">
            <p
              className={combineClassName('text-primary', styles.add_another)}
              onClick={addAnother}
            >
              <span className="border border-primary rounded">
                <Icon name="add_another_primary" />
              </span>
              Add another user
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between flex-wrap gap-1">
          <Button
            size="lg"
            variant="outline-secondary"
            className={styles.add_user_btn}
            onClick={toggleAddUserModal}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            className={styles.add_user_btn}
            onClick={(): any => {
              onAddMembers()
            }}
            disabled={validate()}
          >
            {loading ? 'Please wait...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddUserModal
