import type { ActionInterface } from '../IAction';
import {
  ENQUIRIES_FAILURE, ENQUIRIES_REQUEST, ENQUIRIES_SUCCESS, ENQUIRIES_SUCCESS_META
} from './actions';
import type { IInitialState } from '../IInitialState'

const initialState = {
  loading: true,
  success: false,
  failure: false,
  data: null,
};

const reducer = (state = initialState, action: ActionInterface<unknown>): IInitialState => {
  switch (action.type) {
    case ENQUIRIES_REQUEST:
      return {
        loading: true,
        success: false,
        failure: false,
        data: null,
      };
    case ENQUIRIES_SUCCESS:
      return {
        loading: false,
        success: true,
        failure: false,
        data: action.payload,
      };
    case ENQUIRIES_SUCCESS_META:
      return {
        ...state, meta: action.payload
      };
    case ENQUIRIES_FAILURE:
      return {
        loading: false,
        success: false,
        failure: true,
        data: null,
      };
    default:
      return state;
  }
};

export default reducer;
