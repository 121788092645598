import React, { type FC } from 'react'
import styles from './header.module.css'
import Logo from '../../assets/svgs/HPlayFullBlack.svg'
// import SearchInput from '../SearchInput'
import Icon from '../Icon'
import ProfilePic from '../../assets/images/potriat_placeholder.jpg'
import { combineClassName } from '../../util/utilMethods'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMobileMenu } from '../../redux/mobile-menu/actions'
import type { IUser } from '../../interface/auth'
// import { type ISearchResults, searchByTags } from '../../routes/appSearch'
// import { Link } from 'react-router-dom'

const Header: FC = () => {
  const dispatch = useDispatch()
  const show = useSelector(
    (state: { mobileMenu: { show: boolean } }) => state.mobileMenu.show
  )

  const user = useSelector(
    (state: { currentUser: { data: IUser } }) => state.currentUser.data
  )

  // const [searchText, setSearchText] = useState<string>('')
  // const [searchResults, setSearchResults] = useState<ISearchResults[]>([])

  // useEffect(() => {
  //   if (searchText) {
  //     setSearchResults(searchByTags(searchText))
  //   }
  // }, [searchText])

  const toggleMenu = (): void => {
    dispatch(toggleMobileMenu())
  }

  return (
    <div className={styles.header}>
      <section>
        <div>
          <img src={Logo} alt="logo" className="img-fluid" />
        </div>
        {/* <div className={styles.search_wrapper}>
          <div className={styles.search}>
            <SearchInput
              placeholder="Search"
              handleInputChange={(e: any) => {
                setSearchText(e.target.value)
              }}
            />
          </div>
          <div className={searchText ? styles.search_results : 'd-none'}>
            {searchText && searchResults?.length < 1 ? (
              <p
                className={combineClassName(styles.search_link, 'text-center')}
              >
                No results
              </p>
            ) : null}
            {searchResults?.map((route, index: number) => (
              <Link to={route.path} key={index} className={styles.search_link}>
                <p className="d-flex justify-content-between">
                  <span>{route.name}</span>
                  <span>Matches:{route.matches}</span>
                </p>
              </Link>
            ))}
          </div>
        </div> */}
      </section>
      <div className={styles.profile_container}>
        <Icon name="bell" />
        <div className={styles.profile_pic_container}>
          <img src={ProfilePic} alt="" className="img-fluid w-100 h-100" />
        </div>
        <div className={styles.profile_text}>
          <h3>
            {user?.profile?.firstName ?? ''} {user?.profile?.lastName ?? ''}
          </h3>
          <p>{user?.status ?? ''}</p>
        </div>
      </div>
      <div
        className={combineClassName(
          styles.hamburger,
          show ? styles.change : ''
        )}
        onClick={toggleMenu}
      >
        <div className={styles.bar1}></div>
        <div className={styles.bar2}></div>
        <div className={styles.bar3}></div>
      </div>
    </div>
  )
}

export default Header
