import React, {
  useState,
  type FC,
  type Dispatch,
  type SetStateAction,
} from 'react'
import Icon from '../../../Components/Icon'
import styles from '../advert.module.css'
import ImagePreviewer from '../../../Components/ImagePreview'
import type { IAdvert } from '../../../interface/adverts'
import { useDispatch } from 'react-redux'
import api from '../../../network/api'
import { getAllAdverts } from '../../../redux/adverts/actions'
import Loader from '../../../Components/Loader'
import { handleError } from '../../../util/error'

interface AdvertDisplayProps {
  advert: IAdvert | undefined
  toggleEditAdvertModal: () => void
  setSelected: Dispatch<SetStateAction<IAdvert | undefined>>
}

const AdvertDisplay: FC<AdvertDisplayProps> = ({
  advert,
  toggleEditAdvertModal,
  setSelected,
}) => {
  const dispatch = useDispatch()

  const [deleting, setDeleteing] = useState(false)

  const onDelete = async (): Promise<void> => {
    if (window.confirm('Are you sure you want to delete this advert?')) {
      setDeleteing(true)
      try {
        if (advert?.id) {
          await api.deleteAdvert(advert?.id)
          dispatch(getAllAdverts())
        }
      } catch (error) {
        handleError(error)
      }
      setDeleteing(false)
    }
  }

  return deleting ? (
    <Loader />
  ) : (
    <div className={styles.display}>
      <div className={styles.display_image_wrapper}>
        <ImagePreviewer
          url={advert?.image ?? ''}
          className="h-100 w-100 img-fluid"
          alt="image preview"
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p className={styles.display_name}>{advert?.name}</p>
        <div className="d-flex gap-2 align-items-center">
          <p
            className="cursor-pointer"
            onClick={(): any => {
              onDelete()
            }}
          >
            <Icon name="red-trash" />
          </p>
          <p
            className="cursor-pointer"
            onClick={() => {
              if (advert) {
                setSelected(advert)
                toggleEditAdvertModal()
              }
            }}
          >
            <Icon name="edit" />
          </p>
        </div>
      </div>
    </div>
  )
}

export default AdvertDisplay
