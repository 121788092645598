import React, { useState, type FC, useEffect } from 'react'
import SearchInput from '../../Components/SearchInput'
import Filter from '../../Components/Filter'
import { Button } from 'react-bootstrap'
import styles from './members.module.css'
import Icon from '../../Components/Icon'
import { combineClassName } from '../../util/utilMethods'
import Table from '../../Components/Table'
import AddUserModal from './Components/AddUserModal'
import { useDispatch, useSelector } from 'react-redux'
import type { IMembersState } from '../../interface/members'
import { getAllMembers } from '../../redux/members/actions'
import Loader from '../../Components/Loader'
import PlaceholderImage from '../../assets/images/potriat_placeholder.jpg'
import ImagePreviewer from '../../Components/ImagePreview'
import { showExportModal } from '../../redux/export-list/actions'
import api from '../../network/api'
import type { IQuery, ISearchParams } from '../../interface/network'

const Members: FC = () => {
  const dispatch = useDispatch()

  const {
    data: members,
    loading,
    meta,
  } = useSelector((state: { members: IMembersState }) => state.members)

  const [addUser, setAddUser] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [searchName, setSearchName] = useState<string>('')

  const toggleAddUserModal = (): void => {
    setAddUser(!addUser)
  }

  const getMembers = async (queries?: IQuery): Promise<void> => {
    await dispatch(getAllMembers(queries))
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchText) {
        const params: ISearchParams = { globalSearch: true }
        if (searchName && searchName?.toLowerCase() !== 'all') {
          params.globalSearch = false
          params.searchName = searchName
        }
        ;(async (): Promise<void> => {
          await getMembers({ search: searchText, ...params })
        })()
      } else {
        ;(async (): Promise<void> => {
          await getMembers()
        })()
      }
    }, 2000)
    return () => {
      clearTimeout(getData)
    }
  }, [searchText])

  const setTable = (): any => {
    let theData: any = []
    let count = 0

    if (members.length) {
      for (const member of members) {
        const layer = [
          <td className={styles.name_td} key={members.indexOf(member)}>
            {/* <Form.Check.Input
              // checked={selected.includes(user.userId)}
              // onChange={() => handleSelectOne(user)}
              type="checkbox"
              className={combineClassName(
                'border border-secondary border-2 cursor-pointer',
                styles.checkbox
              )}
            /> */}
            <span className={styles.td_img}>
              {member?.image ? (
                <ImagePreviewer
                  url={member?.image}
                  className="w-100 h-100 img-fluid"
                />
              ) : (
                <img
                  src={PlaceholderImage}
                  alt=""
                  className="w-100 h-100 img-fluid"
                />
              )}
            </span>
            {member?.firstName} {member?.lastName}
          </td>,
          <td key={2}>{member?.email}</td>,
          <td key={3}>{member?.phone ?? 'Not available'}</td>,
          <td key={4}>{member?.package}</td>,
        ]
        theData = [...theData, layer]

        count += 1
        if (count === members.length) {
          return theData
        }
      }
    }
  }
  return (
    <main>
      <AddUserModal addUser={addUser} toggleAddUserModal={toggleAddUserModal} />
      <section className="d-flex justify-content-between flex-wrap gap-2">
        <div className="d-flex gap-2 flex-wrap">
          <SearchInput
            placeholder="Search"
            handleInputChange={(e: any) => {
              setSearchText(e.target.value)
            }}
          />
          <Filter
            listItems={[
              'All',
              'firstName',
              'lastName',
              'email',
              'phone',
              'package',
              'status',
            ]}
            value={searchName}
            setValue={setSearchName}
          />
        </div>
        <div className="d-flex gap-2">
          <Button
            size="lg"
            className={styles.add_btn}
            onClick={toggleAddUserModal}
          >
            <span className="fs-4">&#65291;</span> <span>Add User</span>
          </Button>
          <Button
            size="lg"
            variant="outline-secondary"
            className={combineClassName(styles.add_btn, styles.filter_btn)}
            onClick={() => dispatch(showExportModal(api.getMembers))}
          >
            <span>
              <Icon name="export" />
            </span>
            <span>Export</span>
          </Button>
        </div>
      </section>
      <section className="mt-4">
        {loading ? (
          <Loader />
        ) : (
          <Table
            hideSearchBar
            callBack={getMembers}
            totalResult={meta?.totalResult}
            limit={meta?.limit}
            currentPage={meta?.currentPage}
            numberOfPages={meta?.pages}
            headers={[
              <th key={1}>
                {/* <Form.Check.Input
                // checked={selected.includes(user.userId)}
                // onChange={() => handleSelectOne(user)}
                type="checkbox"
                className={combineClassName(
                  'border border-secondary border-2 cursor-pointer me-4',
                  styles.checkbox
                )}
              /> */}
                Name
              </th>,
              <th key={2}>Email Address</th>,
              <th key={3}>Phone Number</th>,
              <th key={4}>Membership Package</th>,
            ]}
            data={members?.length ? setTable() : []}
          />
        )}
      </section>
    </main>
  )
}

export default Members
