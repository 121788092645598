import React, { type FC, type Dispatch, type SetStateAction } from 'react'
import { Dropdown } from 'react-bootstrap'
// import Icon from '../Icon'
import styles from './filter.module.css'

interface FilterProps {
  listItems: string[]
  value: string
  setValue: Dispatch<SetStateAction<string>>
}

const Filter: FC<FilterProps> = ({ listItems, value, setValue }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className={styles.main_btn}>
        {/* <span className="me-1">
          <Icon name="filter" />
        </span> */}
        {value ? value?.replace(/([A-Z])/g, ' $1') : 'All'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {listItems.map((item, i) => (
          <Dropdown.Item
            key={i}
            onClick={() => {
              setValue(item)
            }}
            className="text-capitalize"
          >
            {item?.replace(/([A-Z])/g, ' $1')}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Filter
