import React, { type FC } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { closeExportModal, exportList } from '../../redux/export-list/actions'
import Select2 from '../Select2'

interface IExportList {
  showModal: boolean
  loading: boolean
  func: any
}

const ExportModal: FC = () => {
  const dispatch = useDispatch()

  const { showModal, loading, func } = useSelector(
    (state: { exportList: IExportList }) => state.exportList
  )

  const handleChange = (value: string): any => dispatch(exportList(func, value))

  return (
    <Modal
      centered
      show={showModal}
      onHide={() => dispatch(closeExportModal())}
    >
      <Modal.Header closeButton className="modal_special_header fs-5">
        Export
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="d-flex justify-content-center p-4">
            <Spinner variant="primary" />
          </div>
        ) : (
          <Select2
            leaveOptionCase
            label="Select Format"
            labelDescription="Formats"
            values={['pdf', 'excel']}
            options={['PDF', 'Excel']}
            onChange={(value) => handleChange(value)}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ExportModal
