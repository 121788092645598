import React, {
  useState,
  type FC,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { Modal } from 'react-bootstrap'
import styles from '../bookings.module.css'
import { combineClassName } from '../../../util/utilMethods'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import SuccessModal from '../../../Components/SuccessModal'
import type { IAddBookingData, ISlot } from '../../../interface/bookings'
import { useDispatch } from 'react-redux'
import { getAllBookings } from '../../../redux/bookings/actions'
import api from '../../../network/api'
import { handleError } from '../../../util/error'

export enum StepsEnum {
  one = 1,
  two = 2,
  three = 3,
}

interface AddBookingModalProps {
  addBooking: boolean
  toggleAddBookingModal: () => void
  booking: IAddBookingData
  setBooking: Dispatch<SetStateAction<IAddBookingData>>
  initialState: IAddBookingData
}

const AddBookingModal: FC<AddBookingModalProps> = ({
  addBooking,
  toggleAddBookingModal,
  booking,
  setBooking,
  initialState,
}) => {
  const dispatch = useDispatch()

  const [step, setStep] = useState<StepsEnum>(StepsEnum.one)
  const [success, setSuccess] = useState(false)
  const [slots, setSlots] = useState<ISlot[]>()
  const [loading, setLoading] = useState(false)

  const toggleSuccess = (): void => {
    setSuccess(!success)
  }

  const getSlots = async (activity: string, date: string): Promise<void> => {
    setLoading(true)
    try {
      const resp = await api.getBookingsSlots({
        activity,
        date,
      })
      setSlots(resp?.data)
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }

  return (
    <>
      <SuccessModal
        show={success}
        onHide={() => {
          dispatch(getAllBookings())
          toggleSuccess()
        }}
        title="Booked"
        description="Activity successfully booked"
      />
      <Modal
        centered
        show={addBooking}
        onHide={() => {
          toggleAddBookingModal()
          setBooking(initialState)
        }}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className={styles.steps_header}>
            <div
              className={combineClassName(
                styles.stepper_wrapper,
                step > StepsEnum.one ? styles.stepper_wrapper_2 : '',
                step === StepsEnum.three ? styles.stepper_wrapper_last : ''
              )}
            >
              <div
                className={combineClassName(
                  styles.stepper,
                  styles.stepper_active
                )}
              >
                <p
                  className={combineClassName(
                    step > StepsEnum.one ? styles.stepper_icon : ''
                  )}
                ></p>
              </div>
              <div
                className={combineClassName(
                  styles.stepper,
                  step > StepsEnum.one ? styles.stepper_active : ''
                )}
              >
                <p
                  className={step > StepsEnum.two ? styles.stepper_icon : ''}
                ></p>
              </div>
              <div
                className={combineClassName(
                  styles.stepper,
                  step === StepsEnum.three ? styles.stepper_active : ''
                )}
              >
                <p
                  className={step > StepsEnum.two ? styles.stepper_icon : ''}
                ></p>
              </div>
            </div>
            <div className={styles.stepper_texts}>
              <p>Choose time</p>
              <p className="ps-4">Your info</p>
              <p>Confirm booking</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === StepsEnum.one ? (
            <Step1
              setStep={setStep}
              booking={booking}
              setBooking={setBooking}
              slots={slots}
              loading={loading}
              getSlots={getSlots}
            />
          ) : step === StepsEnum.two ? (
            <Step2
              setStep={setStep}
              booking={booking}
              setBooking={setBooking}
            />
          ) : (
            <Step3
              setStep={setStep}
              toggleSuccess={toggleSuccess}
              toggleAddBookingModal={toggleAddBookingModal}
              booking={booking}
              initialState={initialState}
              setBooking={setBooking}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddBookingModal
