import React, { type Dispatch, type FC, type SetStateAction } from 'react'
import styles from '../bookings.module.css'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import Icon from '../../../Components/Icon'
import { StepsEnum } from './AddBookingModal'
import type { IAddBookingData, ISlot } from '../../../interface/bookings'
import moment from 'moment'
import { convertTime } from '../../../util/utilMethods'

interface Step1Props {
  setStep: Dispatch<SetStateAction<StepsEnum>>
  booking: IAddBookingData
  setBooking: Dispatch<SetStateAction<IAddBookingData>>
  slots: ISlot[] | undefined
  loading: boolean
  getSlots: (a: string, b: string) => any
}

const Step1: FC<Step1Props> = ({
  setStep,
  booking,
  setBooking,
  slots,
  loading,
  getSlots,
}) => {
  return (
    <main className="d-flex justify-content-center gap-4 flex-wrap">
      <div className={styles.date_time}>
        <h2>Available date</h2>
        <p>Please choose a date below</p>
        <div className={styles.calendar_wrapper}>
          <Calendar
            calendarType="gregory"
            className={styles.calendar}
            tileClassName={styles.calendar_tile}
            minDate={new Date()}
            nextLabel={<Icon name="greater_than" />}
            next2Label={<Icon name="greater_than" />}
            prevLabel={<Icon name="less_than" />}
            prev2Label={<Icon name="less_than" />}
            onChange={(value: any) => {
              setBooking({
                ...booking,
                date: value,
              })
              getSlots(booking?.activity, moment(value).format('DD-MM-YYYY'))
            }}
            value={booking?.date}
          />
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2>Available time</h2>
        <p>Please choose a time below</p>
        {loading ? (
          <p className="py-5 m-auto text-center text-primary">Please wait...</p>
        ) : !booking?.date ? (
          <p className="py-5 m-auto text-center text-primary">
            Please choose a date
          </p>
        ) : slots && slots?.length < 1 ? (
          <p className="py-5 m-auto text-center text-primary">
            No available slot, please choose a new date
          </p>
        ) : (
          slots?.map((a: ISlot, i: number) => {
            if (a?.available) {
              return (
                <div
                  className={styles.time_tile}
                  style={{
                    backgroundColor:
                      booking.startTime === a?.time?.split(' ')[0]
                        ? '#DADADA'
                        : '#fff',
                  }}
                  key={i}
                  onClick={() => {
                    const value: any = { startTime: a?.time?.split(' ')[0] }
                    if (a?.time?.split(' ')[2]) {
                      value.endTime = a?.time?.split(' ')[2]
                    }
                    setBooking({
                      ...booking,
                      ...value,
                    })
                    if (booking.date) {
                      setStep(StepsEnum.two)
                    }
                  }}
                >
                  <p>{convertTime(a?.time?.split(' ')[0])}</p>
                  {a?.time?.split(' ')[2] ? (
                    <p>{` - ${convertTime(a?.time?.split(' ')[2])}`}</p>
                  ) : null}
                </div>
              )
            } else {
              return null
            }
          })
        )}
      </div>
    </main>
  )
}

export default Step1
