import type { ActionInterface } from '../IAction';
import type { AppDispatch } from '../index';
import api from '../../network/api';
import type { IMeta, IQuery } from '../../interface/network';
import type { IEnquiry } from '../../interface/enquiries';

export const ENQUIRIES_REQUEST = 'ENQUIRIES/REQUEST';
export const ENQUIRIES_SUCCESS = 'ENQUIRIES/SUCCESS';
export const ENQUIRIES_SUCCESS_META = 'ENQUIRIES/SUCCESS/META';
export const ENQUIRIES_FAILURE = 'ENQUIRIES/FAILURE';

const enquiriesRequest = (): ActionInterface<unknown> => ({
  type: ENQUIRIES_REQUEST,
});

const enquiriesSuccess = (payload: IEnquiry[]): ActionInterface<unknown> => ({
  type: ENQUIRIES_SUCCESS,
  payload,
});

const enquiriesSuccessMeta = (payload: IMeta): ActionInterface<unknown> => ({
  type: ENQUIRIES_SUCCESS_META,
  payload,
});

const enquiriesFailure = (): ActionInterface<unknown> => ({
  type: ENQUIRIES_FAILURE,
});

export function getAllEnquiries(queries?: IQuery): any {
  return async (dispatch: AppDispatch) => {
    dispatch(enquiriesRequest());
    try {
      const { data, meta } = await api.getEnquiries(queries);
      dispatch(enquiriesSuccess(data));
      dispatch((enquiriesSuccessMeta(meta)))
    } catch (error: any) {
      dispatch(enquiriesFailure());
    }
  };
}
