import React, { type FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import styles from './auth.module.css'
import * as storage from '../../util/localStorage'

const Auth: FC = () => {
  if (storage.getItem('accessToken')) {
    return <Navigate to="/login" replace />
  }
  return (
    <main className={styles.wrapper}>
      <section className={styles.left_wrapper}></section>
      <section className={styles.right_wrapper}>
        <Outlet />
      </section>
    </main>
  )
}

export default Auth
