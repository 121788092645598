import React, { useState, type FC, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import styles from '../dashboard.module.css'
import ReactApexChart from 'react-apexcharts'
import type { IBookingRequests } from '../../../interface/analytics'
import api from '../../../network/api'
import { handleError } from '../../../util/error'

const LineChart: FC = () => {
  const [requests, setRequests] = useState<IBookingRequests>()
  const [year, setYear] = useState(
    requests?.availableYears[0] ?? new Date().getFullYear()
  )

  const getData = (): number[] => {
    const result = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    if (requests) {
      requests.record.forEach((request) => {
        result[request.month - 1] = request.bookingCount
      })
    }
    return result
  }

  const data = {
    options: {
      chart: {
        parentHeightOffset: 0,
        toolbar: { show: false },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
        colors: '#0021f3',
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
    yaxis: {
      max: 100,
    },
    series: [
      {
        name: 'bookings',
        data: getData(),
      },
    ],
  }
  const getRequests = async (): Promise<void> => {
    try {
      const resp = await api.getBookingRequests({ year })
      setRequests(resp.data)
    } catch (error: any) {
      handleError(error)
    }
  }

  useEffect(() => {
    getRequests()
  }, [year])

  return (
    <section className={styles.chart_conatiner}>
      <div className={styles.chart_header}>
        <div className={styles.chart_header_text}>
          <h3>Booking Request Per Month</h3>
          <p>Jan - Dec</p>
        </div>
        <Dropdown className={styles.chart_dropdown}>
          <Dropdown.Toggle variant="primary">{year}</Dropdown.Toggle>
          <Dropdown.Menu>
            {requests?.availableYears?.map((year, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => {
                  setYear(year)
                }}
              >
                {year}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={styles.chart_area}>
        <ReactApexChart
          type="line"
          // @ts-expect-error chart error
          options={data.options}
          series={data.series}
          height="100%"
          width="100%"
        />
      </div>
    </section>
  )
}

export default LineChart
