import React, { type FC } from 'react'
import styles from './menu.module.css'
import Icon from '../Icon'
import { NavLink, useNavigate } from 'react-router-dom'
import { combineClassName } from '../../util/utilMethods'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMobileMenu } from '../../redux/mobile-menu/actions'
import * as storage from '../../util/localStorage'
import { toggleSideBar } from '../../redux/side-bar/actions'

const Menu: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const show = useSelector(
    (state: { mobileMenu: { show: boolean } }) => state.mobileMenu.show
  )
  const collapse = useSelector(
    (state: { sideBar: { collapse: boolean } }) => state.sideBar.collapse
  )

  const toggleMenu = (): void => {
    dispatch(toggleMobileMenu())
  }
  const navClass = ({ isActive }: { isActive: any }): string =>
    combineClassName(
      combineClassName(styles.nav, collapse ? styles.collapsed_nav : ''),
      isActive ? styles.active : ''
    )

  const logOut = (): void => {
    storage.clear()
    toggleMenu()
    navigate('/login', { replace: true })
  }

  return (
    <main
      className={combineClassName(
        styles.menu,
        collapse ? styles.collapsed_menu : '',
        !show ? styles.hide_mobile_menu : ''
      )}
    >
      <div className={styles.collapse}>
        <div className={collapse ? 'd-none' : ''}></div>
        <p
          className="d-flex"
          onClick={() => {
            dispatch(toggleSideBar())
          }}
        >
          <span
            className={combineClassName(
              styles.collapse_icon,
              collapse ? styles.collapsed_icon : ''
            )}
          >
            <Icon name="less_than" />
          </span>
          <span
            className={combineClassName(
              styles.collapse_text,
              collapse ? 'd-none' : ''
            )}
          >
            Collapse
          </span>
        </p>
      </div>
      <section className={styles.pages}>
        <NavLink to="/" className={navClass} onClick={toggleMenu}>
          <span>
            <Icon name="dashboard" />
          </span>
          <span className={collapse ? 'd-none' : ''}>Dashboard</span>
        </NavLink>
        <NavLink to="/members" className={navClass} onClick={toggleMenu}>
          <span>
            <Icon name="members" />
          </span>
          <span className={collapse ? 'd-none' : ''}>Members</span>
        </NavLink>
        <NavLink to="/bookings" className={navClass} onClick={toggleMenu}>
          <span>
            <Icon name="bookings" />
          </span>
          <span className={collapse ? 'd-none' : ''}>Bookings</span>
        </NavLink>
        <NavLink to="/enquiries" className={navClass} onClick={toggleMenu}>
          <span>
            <Icon name="enquiries" />
          </span>
          <span className={collapse ? 'd-none' : ''}>Enquiries</span>
        </NavLink>
        <NavLink to="/advert" className={navClass} onClick={toggleMenu}>
          <span>
            <Icon name="advert" />
          </span>
          <span className={collapse ? 'd-none' : ''}>Advert</span>
        </NavLink>
      </section>
      {/* Links disabled, activate with NavLink as above */}
      <section className="pt-2">
        <p
          className={combineClassName(
            styles.help_and_support,
            collapse ? 'd-none' : ''
          )}
        >
          Help and Support
        </p>
        <div>
          <div
            className={combineClassName(
              styles.nav,
              collapse ? styles.collapsed_nav : ''
            )}
            onClick={toggleMenu}
          >
            <span>
              <Icon name="settings" />
            </span>
            <span className={collapse ? 'd-none' : ''}>Settings</span>
          </div>
          <div
            className={combineClassName(
              styles.nav,
              collapse ? styles.collapsed_nav : ''
            )}
            onClick={toggleMenu}
          >
            <span>
              <Icon name="help" />
            </span>
            <span className={collapse ? 'd-none' : ''}>Help center</span>
          </div>
        </div>
      </section>
      <div className={styles.logout}>
        <div
          className={combineClassName(
            combineClassName(styles.nav, collapse ? styles.collapsed_nav : ''),
            'cursor-pointer'
          )}
          onClick={logOut}
        >
          <span>
            <Icon name="logout" />
          </span>
          <span className={collapse ? 'd-none' : ''}>Logout</span>
        </div>
      </div>
    </main>
  )
}

export default Menu
