import React, { useState, type FC, useEffect } from 'react'
import SearchInput from '../../Components/SearchInput'
import Filter from '../../Components/Filter'
import { Button } from 'react-bootstrap'
import styles from './enquiries.module.css'
import Icon from '../../Components/Icon'
import { combineClassName } from '../../util/utilMethods'
import Table from '../../Components/Table'
import Image from '../../assets/images/member_table_image.png'
import ViewInquiryModal from './Components/ViewInquiryModal'
import { useDispatch, useSelector } from 'react-redux'
import type { IEnquiriesState, IEnquiry } from '../../interface/enquiries'
import type { IQuery, ISearchParams } from '../../interface/network'
import { getAllEnquiries } from '../../redux/enquiries/actions'
import Loader from '../../Components/Loader'
import api from '../../network/api'
import { showExportModal } from '../../redux/export-list/actions'

const Enquiries: FC = () => {
  const dispatch = useDispatch()

  const {
    data: enquiries,
    loading,
    meta,
  } = useSelector((state: { enquiries: IEnquiriesState }) => state.enquiries)

  const [viewEnquiry, setViewEnquiry] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [searchName, setSearchName] = useState<string>('')
  const [selected, setSelected] = useState<IEnquiry>()

  const toggleViewEnquiry = (): void => {
    setViewEnquiry(!viewEnquiry)
  }

  const getEnquiries = async (queries?: IQuery): Promise<void> => {
    await dispatch(getAllEnquiries(queries))
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchText) {
        const params: ISearchParams = { globalSearch: true }
        if (searchName && searchName?.toLowerCase() !== 'all') {
          params.globalSearch = false
          params.searchName = searchName
        }
        ;(async (): Promise<void> => {
          await getEnquiries({ search: searchText, ...params })
        })()
      } else {
        ;(async (): Promise<void> => {
          await getEnquiries()
        })()
      }
    }, 2000)
    return () => {
      clearTimeout(getData)
    }
  }, [searchText])

  const setTable = (): any => {
    let theData: any = []
    let count = 0

    if (enquiries.length) {
      for (const enquiry of enquiries) {
        const layer = [
          <td className={styles.name_td} key={enquiries.indexOf(enquiry)}>
            {/* <Form.Check.Input
              // checked={selected.includes(user.userId)}
              // onChange={() => handleSelectOne(user)}
              type="checkbox"
              className={combineClassName(
                'border border-secondary border-2 cursor-pointer',
                styles.checkbox
              )}
            /> */}
            <span className={styles.td_img}>
              <img src={Image} alt="" className="w-100 h-100 img-fluid" />
            </span>
            {enquiry?.name}
          </td>,
          <td key={2}>{enquiry?.email}</td>,
          <td key={3}>{enquiry?.phone ?? 'Not available'}</td>,
          <td key={4}>{enquiry?.type}</td>,
          <td key={5}>
            <Button
              onClick={() => {
                setSelected(enquiry)
                toggleViewEnquiry()
              }}
            >
              View Inquiry
            </Button>
          </td>,
        ]
        theData = [...theData, layer]

        count += 1
        if (count === enquiries.length) {
          return theData
        }
      }
    }
  }
  return (
    <main>
      <ViewInquiryModal
        viewEnquiry={viewEnquiry}
        toggleViewEnquiry={toggleViewEnquiry}
        selected={selected}
      />
      <section className="d-flex justify-content-between flex-wrap gap-2">
        <div className="d-flex gap-2 flex-wrap">
          <SearchInput
            placeholder="Search"
            handleInputChange={(e: any) => {
              setSearchText(e.target.value)
            }}
          />
          <Filter
            listItems={['All', 'name', 'email', 'type', 'phone']}
            value={searchName}
            setValue={setSearchName}
          />
        </div>
        <div className="d-flex gap-2">
          <Button
            size="lg"
            variant="outline-secondary"
            className={combineClassName(styles.add_btn, styles.filter_btn)}
            onClick={() => dispatch(showExportModal(api.getEnquiries))}
          >
            <span>
              <Icon name="export" />
            </span>
            <span>Export</span>
          </Button>
        </div>
      </section>
      <section className="mt-4">
        {loading ? (
          <Loader />
        ) : (
          <Table
            hideSearchBar
            callBack={getEnquiries}
            totalResult={meta?.totalResult}
            limit={meta?.limit}
            currentPage={meta?.currentPage}
            numberOfPages={meta?.pages}
            headers={[
              <th key={1}>
                {/* <Form.Check.Input
                // checked={selected.includes(user.userId)}
                // onChange={() => handleSelectOne(user)}
                type="checkbox"
                className={combineClassName(
                  'border border-secondary border-2 cursor-pointer me-4',
                  styles.checkbox
                )}
              /> */}
                Name
              </th>,
              <th key={2}>Email Address</th>,
              <th key={3}>Phone Number</th>,
              <th key={4}>Type</th>,
              <th key={5}>Action</th>,
            ]}
            data={enquiries?.length ? setTable() : []}
          />
        )}
      </section>
    </main>
  )
}

export default Enquiries
