import React, { type FC } from 'react'
import { Modal } from 'react-bootstrap'
import styles from '../bookings.module.css'
import type { IBooking } from '../../../interface/bookings'
import moment from 'moment'
import { convertTime } from '../../../util/utilMethods'

interface ViewBookingModalProps {
  viewBooking: boolean
  toggleViewBookingModal: () => void
  selected: IBooking | undefined
}

const ViewBookingModal: FC<ViewBookingModalProps> = ({
  viewBooking,
  toggleViewBookingModal,
  selected,
}) => {
  return (
    <Modal
      size="lg"
      show={viewBooking}
      centered
      onHide={toggleViewBookingModal}
    >
      <Modal.Header closeButton className={styles.view_bookings_header}>
        <Modal.Title className={styles.wrapper}>
          <h2>Preview booking</h2>
          <p className="mb-0">Booking information below</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.view_bookings_body}>
        <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center border-bottom border-2 mt-3">
          <p className={styles.step3_key}>Name</p>
          <p className={styles.step3_value}>
            {selected?.slot?.member?.firstName}{' '}
            {selected?.slot?.member?.lastName}
          </p>
        </div>
        <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center border-bottom border-2 mt-3">
          <p className={styles.step3_key}>Email</p>
          <p className={styles.step3_value}>{selected?.slot?.member?.email}</p>
        </div>
        <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center border-bottom border-2 mt-3">
          <p className={styles.step3_key}>Phone Number</p>
          <p className={styles.step3_value}>{selected?.slot?.member?.phone}</p>
        </div>
        <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center border-bottom border-2 mt-3">
          <p className={styles.step3_key}>Activity Booked</p>
          <p className={styles.step3_value}>
            {selected?.activity?.replaceAll('_', ' ')}
          </p>
        </div>
        <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center border-bottom border-2 mt-3">
          <p className={styles.step3_key}>Status</p>
          <p className={styles.step3_value}>{selected?.status}</p>
        </div>
        <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center border-bottom border-2 mt-3">
          <p className={styles.step3_key}>Payment Status</p>
          <p className={styles.step3_value}>{selected?.paymentStatus}</p>
        </div>
        <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center border-bottom border-2 mt-3">
          <p className={styles.step3_key}>Date</p>
          <p className={styles.step3_value}>
            {moment(
              selected?.slot?.date?.split('-')?.reverse()?.join('-')
            ).format('Do, MMMM YYYY')}
          </p>
        </div>
        <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center border-bottom border-2 mt-3">
          <p className={styles.step3_key}>Time</p>
          <p className={styles.step3_value}>
            {convertTime(selected?.slot?.startTime)}{' '}
            {selected?.slot?.endTime ? (
              <span>{` - ${convertTime(selected?.slot?.endTime)}`}</span>
            ) : null}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ViewBookingModal
