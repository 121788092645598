import React, { useState, type FC, type ChangeEvent, useEffect } from 'react'
import Logo from '../../assets/svgs/HPlayFullBlack.svg'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styles from './resetpassword.module.css'
import { combineClassName } from '../../util/utilMethods'
import Icon from '../../Components/Icon'
import api from '../../network/api'
import { toast } from 'react-toastify'
import validator from 'validator'
import { handleError } from '../../util/error'
import Loader from '../../Components/Loader'

interface IAuth {
  email: string
  password: string
  passwordConfirmation: string
}

const authInitialState = {
  email: '',
  password: '',
  passwordConfirmation: '',
}

const ResetPassword: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [auth, setAuth] = useState<IAuth>(authInitialState)
  const [validatingToken, setValidatingToken] = useState<boolean>(false)

  useEffect(() => {
    if (token) {
      validateToken()
    }
  }, [token])

  const togglePassword = (): void => {
    setShowPassword(!showPassword)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setAuth({ ...auth, [e.target.name]: e.target.value })
  }

  const validateToken = async (): Promise<void> => {
    setValidatingToken(true)
    try {
      if (token) {
        await api.verifyResetToken(token)
      }
    } catch (error: any) {
      navigate('/login', { replace: true })
      handleError(error)
    }
    setValidatingToken(false)
  }

  const requestToken = async (): Promise<void> => {
    setLoading(true)
    try {
      const { email } = auth
      const resp = await api.requestResetToken({ email })
      navigate('/login', { replace: true })
      toast.success(
        resp?.message ?? 'Further steps has been sent to your email'
      )
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }

  const resetPassword = async (): Promise<void> => {
    setLoading(true)
    try {
      const { password, passwordConfirmation } = auth
      if (token) {
        const resp = await api.resetPassword({
          passwordConfirmation,
          password,
          token,
        })
        navigate('/login', { replace: true })
        toast.success(
          resp?.message ?? 'Password reset successful, proceed to login'
        )
      }
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }

  const onSubmit = async (): Promise<void> => {
    await (token ? resetPassword() : requestToken())
  }

  const validate = (): boolean => {
    if (!token) {
      return loading || !validator.isEmail(auth.email)
    }
    return (
      loading || !auth.password || auth.password !== auth.passwordConfirmation
    )
  }

  return validatingToken ? (
    <Loader />
  ) : (
    <main className={styles.wrapper}>
      <div>
        <img src={Logo} alt="logo" />
      </div>
      <h1 className={styles.heading}>
        {token ? 'Enter new password' : 'Enter your email'}
      </h1>
      <div>
        {!token ? (
          <FloatingLabel label="Email address" className="mb-4">
            <Form.Control
              type="email"
              placeholder="Enter your email"
              onChange={handleChange}
              value={auth.email}
              name="email"
            />
          </FloatingLabel>
        ) : (
          <>
            <FloatingLabel
              label="Password"
              className={combineClassName('mb-2', styles.password_input)}
            >
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter your password"
                onChange={handleChange}
                value={auth.password}
                name="password"
              />
              <div className={styles.password_icon} onClick={togglePassword}>
                <Icon name={showPassword ? 'hide_password' : 'show_password'} />
              </div>
            </FloatingLabel>
            <FloatingLabel
              label="Confirm password"
              className={combineClassName('mb-2', styles.password_input)}
            >
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter your password"
                onChange={handleChange}
                value={auth.passwordConfirmation}
                name="passwordConfirmation"
              />
              <div className={styles.password_icon} onClick={togglePassword}>
                <Icon name={showPassword ? 'hide_password' : 'show_password'} />
              </div>
            </FloatingLabel>
          </>
        )}
      </div>
      <div
        className={combineClassName(
          'd-flex justify-content-end',
          styles.forgot_password
        )}
      >
        <Link to="/login" className="text-primary text-decoration-none">
          Login?
        </Link>
      </div>
      <Button
        className="w-100"
        size="lg"
        disabled={validate()}
        onClick={(): any => {
          onSubmit()
        }}
      >
        {loading ? 'Please wait...' : 'Submit'}
      </Button>
    </main>
  )
}

export default ResetPassword
