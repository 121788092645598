import React, {
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'
import styles from '../bookings.module.css'
import { StepsEnum } from './AddBookingModal'
import { combineClassName, convertTime } from '../../../util/utilMethods'
import { Button } from 'react-bootstrap'
import type { IAddBookingData } from '../../../interface/bookings'
import api from '../../../network/api'
import { handleError } from '../../../util/error'
import moment from 'moment'

interface Step3Props {
  setStep: Dispatch<SetStateAction<StepsEnum>>
  toggleSuccess: () => any
  toggleAddBookingModal: () => void
  booking: IAddBookingData
  setBooking: Dispatch<SetStateAction<IAddBookingData>>
  initialState: IAddBookingData
}

const Step3: FC<Step3Props> = ({
  setStep,
  toggleSuccess,
  toggleAddBookingModal,
  booking,
  setBooking,
  initialState,
}) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = async (): Promise<void> => {
    setLoading(true)
    try {
      const values = { ...booking }
      values.date = moment(values?.date).format('DD-MM-YYYY')
      await api.addBooking(values)
      toggleAddBookingModal()
      toggleSuccess()
      setStep(StepsEnum.one)
      setBooking(initialState)
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }
  return (
    <main className={combineClassName(styles.wrapper, 'p-2')}>
      <h2>Preview booking</h2>
      <p className="border-bottom border-2 text-capitalize pb-3">
        Booking information below
      </p>
      <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center border-bottom border-2 text-capitalize">
        <p className={styles.step3_key}>Package</p>
        <p className={styles.step3_value}>
          {booking.activity.replaceAll('_', ' ')}
        </p>
      </div>
      <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center pt-3 border-bottom border-2 text-capitalize">
        <p className={styles.step3_key}>Name</p>
        <p
          className={styles.step3_value}
        >{`${booking.firstName} ${booking.lastName}`}</p>
      </div>
      <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center pt-3 border-bottom border-2">
        <p className={styles.step3_key}>Email</p>
        <p className={styles.step3_value}>{booking.email}</p>
      </div>
      <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center pt-3 border-bottom border-2">
        <p className={styles.step3_key}>Phone</p>
        <p className={styles.step3_value}>{booking.phone}</p>
      </div>
      <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center pt-3 border-bottom border-2 text-capitalize">
        <p className={styles.step3_key}>Date</p>
        <p className={styles.step3_value}>
          {moment(booking?.date).format('Do MMMM YYYY')}
        </p>
      </div>
      <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center pt-3 border-bottom border-2 text-capitalize">
        <p className={styles.step3_key}>Time</p>
        <p className={styles.step3_value}>
          {convertTime(booking?.startTime)}
          {booking?.endTime ? (
            <span>{` - ${convertTime(booking?.endTime)}`}</span>
          ) : null}
        </p>
      </div>
      <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center pt-3 border-bottom border-2">
        <p className={styles.step3_key}>Note</p>
        <p className={styles.step3_value}>{booking.note}</p>
      </div>
      <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center pt-3">
        <Button
          size="lg"
          variant="outline-primary"
          style={{ width: '10.5rem' }}
          onClick={() => {
            setStep(StepsEnum.two)
          }}
        >
          Back
        </Button>
        <Button
          size="lg"
          onClick={(): any => {
            onSubmit()
          }}
          disabled={loading}
        >
          {loading ? 'Please wait...' : 'Confirm'}
        </Button>
      </div>
    </main>
  )
}

export default Step3
