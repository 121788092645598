import { handleError } from '../../util/error';
import type { ActionInterface } from '../IAction';
import type { AppDispatch } from '../index';

export const SHOW_EXPORT_MODAL = 'SHOW/EXPORT/MODAL'
export const CLOSE_EXPORT_MODAL = 'CLOSE/EXPORT/MODAL'
export const EXPORT_REQUEST = 'EXPORT/REQUEST'

export const showExportModal = (payload: (downloadFormat?: { downloadFormat: 'pdf' | 'excel' }) => any,): ActionInterface<unknown> => ({
    type: SHOW_EXPORT_MODAL,
    payload
});

export const closeExportModal = (): ActionInterface<unknown> => ({
    type: CLOSE_EXPORT_MODAL,
});

const exportRequest = (): ActionInterface<unknown> => ({
    type: EXPORT_REQUEST,
});

export function exportList(
    func: (downloadFormat?: { downloadFormat: string }) => any,
    downloadFormat: string
): any {
    return async (dispatch: AppDispatch) => {
        dispatch(exportRequest());
        try {
            const { meta } = await func({ downloadFormat });
            dispatch(closeExportModal());
            window.location.href = meta?.downloadUrl
        } catch (error: any) {
            dispatch(closeExportModal());
            handleError(error)
        }
    };
}
