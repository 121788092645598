import React, {
  type ChangeEvent,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'
import styles from '../bookings.module.css'
import { StepsEnum } from './AddBookingModal'
import {
  combineClassName,
  phoneNumberValidation,
} from '../../../util/utilMethods'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import type { IAddBookingData } from '../../../interface/bookings'
import validator from 'validator'

interface Step2Props {
  setStep: Dispatch<SetStateAction<StepsEnum>>
  booking: IAddBookingData
  setBooking: Dispatch<SetStateAction<IAddBookingData>>
}

const Step2: FC<Step2Props> = ({ setStep, booking, setBooking }) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ): void => {
    let value = e.target.value
    if (e.target.name === 'phone') {
      value = phoneNumberValidation(value)
    }
    setBooking({ ...booking, [e.target.name]: value })
  }
  const disable = (): boolean =>
    !booking.activity ||
    !validator.isEmail(booking.email) ||
    !booking.firstName ||
    !booking.lastName ||
    !booking.phone
  return (
    <main className={combineClassName(styles.wrapper, styles.step_2)}>
      <h2>Customer information</h2>
      <p>Please enter customer information below</p>
      <div>
        <div
          className={combineClassName(
            'd-flex justify-content-between gap-3 mt-3',
            styles.inputs_wrapper
          )}
        >
          <FloatingLabel label="First name" className="w-100">
            <Form.Control
              type="text"
              placeholder="First name"
              value={booking.firstName}
              name="firstName"
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel label="Last name" className="w-100">
            <Form.Control
              type="text"
              placeholder="Last name"
              value={booking.lastName}
              name="lastName"
              onChange={handleChange}
            />
          </FloatingLabel>
        </div>
        <div
          className={combineClassName(
            'd-flex justify-content-between gap-3 mt-3',
            styles.inputs_wrapper
          )}
        >
          <FloatingLabel label="Email address" className="w-100">
            <Form.Control
              type="email"
              placeholder="Email address"
              value={booking.email}
              name="email"
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel label="Phone number" className="w-100">
            <Form.Control
              type="text"
              placeholder="Phone number"
              value={booking.phone}
              name="phone"
              onChange={handleChange}
              maxLength={11}
            />
          </FloatingLabel>
        </div>
        <div className="mt-3">
          <FloatingLabel label="Additional note">
            <Form.Control
              as="textarea"
              placeholder="Include any additional note"
              style={{ height: '8.3125rem' }}
              value={booking.note}
              onChange={handleChange}
              name="note"
            />
          </FloatingLabel>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center pt-3">
        <Button
          size="lg"
          variant="outline-primary"
          style={{ width: '10.5rem' }}
          onClick={() => {
            setStep(StepsEnum.one)
          }}
        >
          Back
        </Button>
        <Button
          size="lg"
          style={{ width: '10.5rem' }}
          onClick={() => {
            setStep(StepsEnum.three)
          }}
          disabled={disable()}
        >
          Confirm
        </Button>
      </div>
    </main>
  )
}

export default Step2
