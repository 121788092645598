/* eslint-disable react/react-in-jsx-scope */
import { type FC, useState } from 'react'
import styles from './Select.module.css'
import Cancel from '../../assets/svgs/exit.svg'
import ArrowDown from '../../assets/svgs/dropdown.svg'
import { formatRequestType } from '../../util/utilMethods'

export interface ISelect2 {
  onChange?: (text: string) => void
  labelDescription?: string
  label?: string
  values: string[]
  options: string[]
  hideLabel?: boolean
  selectedValue?: string
  validation?: boolean
  errorMessage?: string
  /**
   * Leave the options casing as provided
   */
  leaveOptionCase?: boolean
}

const Select2: FC<ISelect2> = ({
  label,
  values,
  options,
  onChange,
  labelDescription,
  hideLabel = false,
  selectedValue = '',
  validation = true,
  errorMessage,
  leaveOptionCase,
}) => {
  const [currentValue, setCurrentValue] = useState('')
  const [touched, setTouched] = useState(false)
  const [open, setOpen] = useState(false)

  const handleOpen = (): void => {
    setOpen(!open)
  }
  const handleClose = (): void => {
    setOpen(false)
  }
  const handleValueChange = (value: any): void => {
    setCurrentValue(value)
  }
  const handleChange = (value: any): void => {
    if (onChange) onChange(value)
    handleClose()
  }

  return (
    <div
      onClick={handleOpen}
      style={{ cursor: 'pointer' }}
      className={styles.containerClassName}
    >
      <div className={styles.SelectContainer}>
        {!hideLabel && <div className={styles.label}>{label}</div>}

        <div
          tabIndex={1}
          onBlur={() => {
            setTouched(true)
          }}
          className={styles.SelectLabelButton}
        >
          <div
            className={
              currentValue || selectedValue
                ? styles.currentValue
                : styles.placeholder
            }
          >
            {currentValue || selectedValue
              ? formatRequestType(
                  currentValue || options[values.indexOf(selectedValue)]
                )
              : label}
          </div>
          <div>
            {' '}
            <img src={ArrowDown} alt="arrow dow" />{' '}
          </div>
        </div>

        {validation && touched ? (
          <span className={styles.valid_errormessage}>{errorMessage}</span>
        ) : null}

        {open && (
          <div className={styles.full_dropdown}>
            <div className={styles.description_head}>
              <span className="text-primary fw-bold">{labelDescription}</span>
              <img
                src={Cancel}
                alt="cancel"
                onClick={() => {
                  setOpen(false)
                }}
                style={{ width: '24px', height: '24px' }}
              />
            </div>
            <div className={styles.dropdown_elements}>
              {options.map((option: any, index: any) => (
                <div
                  className={styles.DropdownItem}
                  onClick={() => {
                    handleChange(values[index] ?? '')
                    handleValueChange(option)
                  }}
                  key={index}
                >
                  {leaveOptionCase ? option : formatRequestType(option)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Select2
