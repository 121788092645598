const BASE_URL = process.env.REACT_APP_BASE_URL;

export default {
    AUTH: `${BASE_URL}/auth`,
    ADVERTS: `${BASE_URL}/adverts`,
    GET_DOCUMENT_LINK: `${BASE_URL}/documents/signed`,
    DASHBOARD_ANALYTICS: `${BASE_URL}/dashboard/analysis`,
    BOOKING_REQUEST: `${BASE_URL}/dashboard/booking-request`,
    TOP_BOOKING: `${BASE_URL}/dashboard/top-booking`,
    BOOKINGS: `${BASE_URL}/bookings`,
    ENQUIRIES: `${BASE_URL}/enquiries`,
    MEMBERS: `${BASE_URL}/members`,
    RESET_PASSWORD: `${BASE_URL}/auth/password/reset`,
}
