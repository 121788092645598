import type { ActionInterface } from '../IAction';
import type { IInitialState } from '../IInitialState';
import {
  USER_FAILURE, USER_REQUEST, USER_SUCCESS,
} from './actions';

const initialState = {
  loading: true,
  success: false,
  failure: false,
  data: null,
};

const reducer = (state = initialState, action: ActionInterface<unknown>): IInitialState => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        loading: true,
        success: false,
        failure: false,
        data: null,
      };
    case USER_SUCCESS:
      return {
        loading: false,
        success: true,
        failure: false,
        data: action.payload,
      };
    case USER_FAILURE:
      return {
        loading: false,
        success: false,
        failure: true,
        data: null,
      };
    default:
      return state;
  }
};

export default reducer;
