import React, { type FC } from 'react'
import Input, { type InputProps } from '../Input'
import Icon from '../Icon'
import styles from './searchInput.module.css'
import { combineClassName } from '../../util/utilMethods'

export interface SearchInputProps extends InputProps {
  containerClassName?: string
}

const SearchInput: FC<SearchInputProps> = ({
  containerClassName,
  ...props
}) => {
  return (
    <div className={combineClassName(styles.container, containerClassName)}>
      <Input {...props} containerClassName={styles.input} className="p-0" />
      <Icon name="search" />
    </div>
  )
}

export default SearchInput
