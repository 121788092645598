import type { ActionInterface } from '../IAction';
import type { AppDispatch } from '../index';
import api from '../../network/api';
import type { IMeta, IQuery } from '../../interface/network';
import type { IBooking } from '../../interface/bookings';

export const BOOKINGS_REQUEST = 'BOOKINGS/REQUEST';
export const BOOKINGS_SUCCESS = 'BOOKINGS/SUCCESS';
export const BOOKINGS_SUCCESS_META = 'BOOKINGS/SUCCESS/META';
export const BOOKINGS_FAILURE = 'BOOKINGS/FAILURE';

const bookingsRequest = (): ActionInterface<unknown> => ({
  type: BOOKINGS_REQUEST,
});

const bookingssSuccess = (payload: IBooking[]): ActionInterface<unknown> => ({
  type: BOOKINGS_SUCCESS,
  payload,
});

const bookingssSuccessMeta = (payload: IMeta): ActionInterface<unknown> => ({
  type: BOOKINGS_SUCCESS_META,
  payload,
});

const bookingsFailure = (): ActionInterface<unknown> => ({
  type: BOOKINGS_FAILURE,
});

export function getAllBookings(queries?: IQuery): any {
  return async (dispatch: AppDispatch) => {
    dispatch(bookingsRequest());
    try {
      const { data, meta } = await api.getBookings(queries);
      dispatch(bookingssSuccess(data));
      dispatch((bookingssSuccessMeta(meta)))
    } catch (error: any) {
      dispatch(bookingsFailure());
    }
  };
}
