import React, { useState, type FC, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import SearchInput from '../../Components/SearchInput'
import Filter from '../../Components/Filter'
import styles from './advert.module.css'
import AdvertDisplay from './Components/AdvertDisplay'
import { combineClassName } from '../../util/utilMethods'
import AddAdvertModal from './Components/AddAdvert'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAdverts } from '../../redux/adverts/actions'
import type { IAdvert, IAdvertsState } from '../../interface/adverts'
import Loader from '../../Components/Loader'
import EditAdvertModal from './Components/EditAdvert'
import type { IQuery, ISearchParams } from '../../interface/network'

const Advert: FC = () => {
  const dispatch = useDispatch()

  const { data: adverts, loading } = useSelector(
    (state: { adverts: IAdvertsState }) => state.adverts
  )

  const [addAdvert, setAddAdvert] = useState(false)
  const [editAdvert, setEditAdvert] = useState(false)
  const [selected, setSelected] = useState<IAdvert>()
  const [searchName, setSearchName] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')

  const getAdverts = async (queries?: IQuery): Promise<void> => {
    await dispatch(getAllAdverts(queries))
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchText) {
        const params: ISearchParams = { globalSearch: true }
        if (searchName && searchName?.toLowerCase() !== 'all') {
          params.globalSearch = false
          params.searchName = searchName
        }
        ;(async (): Promise<void> => {
          await getAdverts({ search: searchText, ...params })
        })()
      } else {
        ;(async (): Promise<void> => {
          await getAdverts()
        })()
      }
    }, 2000)
    return () => {
      clearTimeout(getData)
    }
  }, [searchText])

  const toggleAddAdvertModal = (): void => {
    setAddAdvert(!addAdvert)
  }
  const toggleEditAdvertModal = (): void => {
    setEditAdvert(!editAdvert)
  }

  return (
    <main>
      <AddAdvertModal
        addAdvert={addAdvert}
        toggleAddAdvertModal={toggleAddAdvertModal}
      />
      <EditAdvertModal
        editAdvert={editAdvert}
        selected={selected}
        toggleEditAdvertModal={toggleEditAdvertModal}
      />
      <section className="d-flex justify-content-between flex-wrap gap-2">
        <div className="d-flex gap-2 flex-wrap">
          <SearchInput
            placeholder="Search"
            handleInputChange={(e: any) => {
              setSearchText(e.target.value)
            }}
          />
          <Filter
            listItems={['All', 'name']}
            value={searchName}
            setValue={setSearchName}
          />
        </div>
        <div className="d-flex gap-2">
          <Button
            size="lg"
            className={styles.add_btn}
            onClick={toggleAddAdvertModal}
          >
            <span className="fs-4">&#65291;</span> <span>Add Advert</span>
          </Button>
        </div>
      </section>
      <section
        className={combineClassName(
          'd-flex justify-content-evenly align-items-center flex-wrap gap-1 mt-4',
          styles.adverts_item_container
        )}
      >
        {loading ? (
          <Loader />
        ) : (
          adverts?.map((advert, i) => (
            <AdvertDisplay
              key={i}
              advert={advert}
              toggleEditAdvertModal={toggleEditAdvertModal}
              setSelected={setSelected}
            />
          ))
        )}
      </section>
    </main>
  )
}

export default Advert
