import { type ActionInterface } from '../IAction';
import {
    TOGGLE_MOBILE_MENU
} from './actions';

export interface IMobileMenuInitialState {
    show: boolean
}

const initialState = {
    show: false
};

const reducer = (state = initialState, action: ActionInterface<unknown>): IMobileMenuInitialState => {
    switch (action.type) {
        case TOGGLE_MOBILE_MENU:
            return {
                show: !state.show
            };
        default:
            return state;
    }
};

export default reducer;
