import Advert from '../Screens/Advert';
import Bookings from '../Screens/Bookings';
import Dashboard from '../Screens/Dasoboard';
import Enquiries from '../Screens/Enquires';
import Members from '../Screens/Members';

const privateRoutes = [
  { path: '/', element: Dashboard },
  { path: '/members', element: Members },
  { path: '/bookings', element: Bookings },
  { path: '/enquiries', element: Enquiries },
  { path: '/advert', element: Advert }
]

export default privateRoutes;
