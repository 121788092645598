import React, { useState, type FC } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import styles from '../bookings.module.css'
import { handleError } from '../../../util/error'
import { useDispatch } from 'react-redux'
import api from '../../../network/api'
import { getAllBookings } from '../../../redux/bookings/actions'

enum BookingStatusEnum {
  // unBooked = 'not_booked',
  booked = 'booked',
  // pending = 'pending',
  cancelled = 'cancelled',
}

interface StatusColor {
  color: string
  background: string
}

export const statusColor = (status: string): StatusColor => {
  switch (status) {
    // case BookingStatusEnum.pending:
    //   return {
    //     color: '#BF9214',
    //     background: '#FFFAE9',
    //   }
    case BookingStatusEnum.booked:
      return {
        color: '#188E47',
        background: '#E8F4ED',
      }
    default:
      return {
        color: '#1C201F',
        background: '#E9E9E9',
      }
  }
}

interface BookingStatusProps {
  status: string
  id: string
}

const BookingStatus: FC<BookingStatusProps> = ({ status, id }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const handleUpdate = async (selected: BookingStatusEnum): Promise<void> => {
    setLoading(true)
    try {
      await api.updateBooking(id, { status: selected })
      dispatch(getAllBookings())
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="custom" id="dropdown-basic">
        <span className={styles.booking_status} style={statusColor(status)}>
          {loading ? (
            <Spinner variant="primary" />
          ) : (
            status?.replaceAll('_', ' ')
          )}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.values(BookingStatusEnum).map((item, i) => (
          <Dropdown.Item
            key={i}
            onClick={(): any => {
              handleUpdate(item)
            }}
            className="text-capitalize"
          >
            {item?.replaceAll('_', ' ')}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default BookingStatus
