import React, { useState, type FC, useEffect } from 'react'
import SearchInput from '../../Components/SearchInput'
import Filter from '../../Components/Filter'
import { Button } from 'react-bootstrap'
import styles from './bookings.module.css'
import Icon from '../../Components/Icon'
import { combineClassName, convertTime } from '../../util/utilMethods'
import Table from '../../Components/Table'
import BookingStatus from './Components/BookingStatus'
import PaymentStatus from './Components/PaymentStatus'
import AddBookingModal from './Components/AddBookingModal'
import ViewBookingModal from './Components/ViewBookingModal'
import type {
  IAddBookingData,
  IBooking,
  IBookingsState,
} from '../../interface/bookings'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBookings } from '../../redux/bookings/actions'
import Loader from '../../Components/Loader'
import api from '../../network/api'
import { showExportModal } from '../../redux/export-list/actions'
import type { IQuery, ISearchParams } from '../../interface/network'
import ActivityModal from '../../Components/ActivityModal'

const initialState = {
  activity: '',
  date: '',
  note: '',
  startTime: '',
  endTime: '',
  email: '',
  phone: '',
  lastName: '',
  firstName: '',
  method: 'offline',
  paymentStatus: 'unpaid',
  status: 'booked',
}

const Bookings: FC = () => {
  const dispatch = useDispatch()

  const {
    data: bookings,
    loading,
    meta,
  } = useSelector((state: { bookings: IBookingsState }) => state.bookings)

  const [addBooking, setAddBooking] = useState(false)
  const [viewBooking, setViewBooking] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [searchName, setSearchName] = useState<string>('')
  const [selected, setSelected] = useState<IBooking>()
  const [selectActivity, setSelectActivity] = useState(false)
  const [booking, setBooking] = useState<IAddBookingData>(initialState)

  const toggleAddBookingModal = (): void => {
    setAddBooking(!addBooking)
  }
  const toggleViewBookingModal = (): void => {
    setViewBooking(!viewBooking)
  }
  const toggleSelectActivity = (): void => {
    setSelectActivity(!selectActivity)
  }

  const getBookings = async (queries?: IQuery): Promise<void> => {
    await dispatch(getAllBookings(queries))
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchText) {
        const params: ISearchParams = { globalSearch: true }
        if (searchName && searchName?.toLowerCase() !== 'all') {
          params.globalSearch = false
          params.searchName = searchName
        }
        ;(async (): Promise<void> => {
          await getBookings({ search: searchText, ...params })
        })()
      } else {
        ;(async (): Promise<void> => {
          await getBookings()
        })()
      }
    }, 2000)
    return () => {
      clearTimeout(getData)
    }
  }, [searchText])

  const onViewBooking = (data: IBooking): void => {
    setSelected(data)
    toggleViewBookingModal()
  }

  const setTable = (): any => {
    let theData: any = []
    let count = 0

    if (bookings?.length) {
      for (const booking of bookings) {
        const layer = [
          <td className={styles.name_td} key={bookings.indexOf(booking)}>
            {/* <Form.Check.Input
              // checked={selected.includes(user.userId)}
              // onChange={() => handleSelectOne(user)}
              type="checkbox"
              className={combineClassName(
                'border border-secondary border-2 cursor-pointer',
                styles.checkbox
              )}
            /> */}
            <p
              className="cursor-pointer"
              onClick={() => {
                onViewBooking(booking)
              }}
            >
              <span>
                {booking?.slot?.member?.firstName ?? ''}{' '}
                {booking?.slot?.member?.lastName ?? ''}
              </span>
              <br />
              <span className={styles.td_subdata}>
                {booking?.slot?.member?.email ?? ''}
              </span>
            </p>
          </td>,
          <td
            key={2}
            className="cursor-pointer"
            onClick={() => {
              onViewBooking(booking)
            }}
          >
            {booking?.slot?.member?.phone ?? 'Not available'}
          </td>,
          <td
            key={3}
            className="cursor-pointer text-capitalize"
            onClick={() => {
              onViewBooking(booking)
            }}
          >
            {booking?.activity?.replaceAll('_', ' ')}
          </td>,
          <td
            key={4}
            className="cursor-pointer"
            onClick={() => {
              onViewBooking(booking)
            }}
          >
            <span>
              {moment(
                booking?.slot?.date?.split('-')?.reverse()?.join('-')
              ).format('Do MMM YYYY')}
            </span>
            <br />
            <span className={styles.td_subdata}>
              {convertTime(booking?.slot?.startTime)}
              {booking?.slot?.endTime ? (
                <span>{` - ${convertTime(booking?.slot?.endTime)}`}</span>
              ) : null}
            </span>
          </td>,
          <td
            key={5}
            className="cursor-pointer"
            onClick={() => {
              onViewBooking(booking)
            }}
          >
            {booking?.method}
          </td>,
          // design the different colours on implementation
          <td key={6}>
            <BookingStatus status={booking?.status} id={booking?.id} />
          </td>,
          <td key={7}>
            <PaymentStatus status={booking?.paymentStatus} id={booking?.id} />
          </td>,
        ]
        theData = [...theData, layer]

        count += 1
        if (count === bookings.length) {
          return theData
        }
      }
    }
  }

  return (
    <main>
      <ActivityModal
        selectActivity={selectActivity}
        toggleSelectActivity={toggleSelectActivity}
        handleChange={(value: string) => {
          setBooking({ ...booking, activity: value })
          toggleAddBookingModal()
        }}
      />
      <AddBookingModal
        addBooking={addBooking}
        toggleAddBookingModal={toggleAddBookingModal}
        setBooking={setBooking}
        booking={booking}
        initialState={initialState}
      />
      <ViewBookingModal
        viewBooking={viewBooking}
        toggleViewBookingModal={toggleViewBookingModal}
        selected={selected}
      />
      <section className="d-flex justify-content-between flex-wrap gap-2">
        <div className="d-flex gap-2 flex-wrap">
          <SearchInput
            placeholder="Search"
            handleInputChange={(e: any) => {
              const value = e.target.value?.trim()?.replaceAll(' ', '_')
              setSearchText(value)
            }}
          />
          <Filter
            listItems={[
              'All',
              'firstName',
              'lastName',
              'activity',
              'date',
              'time',
              'method',
              'status',
              'paymentStatus',
            ]}
            value={searchName}
            setValue={setSearchName}
          />
        </div>
        <div className="d-flex gap-2">
          <Button
            size="lg"
            className={styles.add_btn}
            onClick={toggleSelectActivity}
          >
            <span className="fs-4">&#65291;</span> <span>Add booking</span>
          </Button>
          <Button
            size="lg"
            variant="outline-secondary"
            className={combineClassName(styles.add_btn, styles.filter_btn)}
            onClick={() => dispatch(showExportModal(api.getBookings))}
          >
            <span>
              <Icon name="export" />
            </span>
            <span>Export</span>
          </Button>
        </div>
      </section>
      <section className="mt-4">
        {loading ? (
          <Loader />
        ) : (
          <Table
            hideSearchBar
            callBack={getBookings}
            totalResult={meta?.totalResult}
            limit={meta?.limit}
            currentPage={meta?.currentPage}
            numberOfPages={meta?.pages}
            headers={[
              <th key={1}>
                {/* <Form.Check.Input
                // checked={selected.includes(user.userId)}
                // onChange={() => handleSelectOne(user)}
                type="checkbox"
                className={combineClassName(
                  'border border-secondary border-2 cursor-pointer me-4',
                  styles.checkbox
                )}
              /> */}
                Name
              </th>,
              <th key={2}>Phone Number</th>,
              <th key={3}>Activity booked</th>,
              <th key={4}>Date & time booked</th>,
              <th key={5}>Booking method</th>,
              <th key={6}>Booking status</th>,
              <th key={7}>Payment status</th>,
            ]}
            data={bookings?.length ? setTable() : []}
          />
        )}
      </section>
    </main>
  )
}

export default Bookings
