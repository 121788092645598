import React, {
  type FC,
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  type ChangeEvent,
} from 'react'
import { combineClassName } from '../../util/utilMethods'
import styles from './FileInput.module.css'
import Icon from '../Icon'

interface FileUploadProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  handleInputChange?: (e: ChangeEvent<HTMLInputElement>) => void
  fileName?: string
  name?: string
  accept?: string
  descrption?: string
}

const FileUpload: FC<FileUploadProps> = ({
  handleInputChange,
  fileName,
  name,
  accept,
  descrption,
  ...props
}) => (
  <label
    htmlFor="formId"
    className={combineClassName(
      'd-flex flex-column justify-content-center align-items-center p-3 rounded gap-2 cursor-pointer',
      styles.wrapper
    )}
  >
    <input
      name={name}
      type="file"
      id="formId"
      onChange={handleInputChange}
      accept={accept}
      {...props}
      hidden
    />
    <Icon name="photo" />
    {fileName ?? (
      <span className="text-center">
        {descrption ?? 'Click to upload'}
        <br />
        {accept && <strong>{`Accepted file format: ${accept}`}</strong>}
      </span>
    )}
  </label>
)

export default FileUpload
