import React, {
  type ChangeEvent,
  cloneElement,
  type CSSProperties,
  type DetailedHTMLProps,
  type FC,
  type HTMLAttributes,
  type InputHTMLAttributes,
  type ReactElement,
  useState,
} from 'react'
import ShowIcon from '../../assets/svgs/passwordIcon.svg'
import { combineClassName } from '../../util/utilMethods'
import './styles.scss'

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  /**
   * The input label which appears at the top
   */
  inputLabel?: any

  /**
   * The html input wrapper inline css
   * @note the wrapper is the top layer
   */
  containerStyle?: CSSProperties

  /**
   * The html input wrapper css class
   * @note the wrapper is the top layer
   */
  containerClassName?: string
  /**
   * Is input valid
   */
  validation?: boolean

  types?: 'text' | 'password' | 'tel' | 'currency' | 'date' | 'number'

  errorMessage?: string

  editable?: boolean
  readOnly?: boolean

  body?: string | ReactElement<HTMLAttributes<HTMLHtmlElement>>

  handleInputChange?: (e: ChangeEvent<HTMLInputElement>) => void
  innerTextCheck?: boolean
  innerText?: any
}

const Input: FC<InputProps> = ({
  className,
  body,
  readOnly,
  inputLabel,
  editable,
  types = 'text',
  handleInputChange,
  containerStyle,
  defaultValue,
  containerClassName,
  validation = true,
  errorMessage,
  innerText,
  innerTextCheck = false,
  ...props
}) => {
  const [touched, setTouched] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const togglePasswordVisibility = (): void => {
    setPasswordVisibility(!passwordVisibility)
  }
  const renderBody = (): any => {
    if (body) {
      if (typeof body === 'string') {
        return <p className="editable">{body}</p>
      } else {
        return cloneElement(body, {
          className: combineClassName(body.props.className, 'editable'),
        })
      }
    } else if (editable ?? typeof editable === 'undefined') {
      return (
        <div className="input_main_div">
          <input
            onBlur={() => {
              setTouched(true)
            }}
            {...props}
            readOnly={readOnly}
            defaultValue={defaultValue}
            onChange={handleInputChange}
            type={types === 'password' && passwordVisibility ? 'text' : types}
            className={combineClassName('input', className)}
          />
          {types === 'password' && (
            <div className="display_icon" onClick={togglePasswordVisibility}>
              <img src={ShowIcon} alt="profile icon" />{' '}
            </div>
          )}
        </div>
      )
    } else {
      return <p className="editable">{props.value}</p>
    }
  }

  return (
    <>
      <div className={containerClassName} style={containerStyle}>
        {inputLabel ? <p className="input_label">{inputLabel}</p> : null}
        {renderBody()}
        {innerTextCheck && <div className="inner-input-div">aa{innerText}</div>}
      </div>

      {validation && touched ? (
        <span className="valid_errormessage">{errorMessage}</span>
      ) : null}
    </>
  )
}

export default Input
