import type { IGetAdvertsResp } from '../interface/adverts';
import type { IBookingRequestsQuery, IBookingRequestsResp, IDashboardAnalyticsResp, ITopBookingQuery, ITopBookingResp } from '../interface/analytics';
import type { ILoggedInUserResp, ILoginData, ILoginUserResp } from '../interface/auth';
import type { IDocumentUrlResp } from '../interface/documents';
import type { INetworkResponse, IQuery } from '../interface/network';
import endpoints from './endpoints';
import { requestMaker } from './request';
import * as storage from '../util/localStorage'
import type { IAddBookingData, IGEtBookingSlotsQuery, IGetBookingsResp, IGetBookingsSlotsResp, IUpdateBookingData } from '../interface/bookings';
import type { IGetEnqiriesResp } from '../interface/enquiries';
import type { IGetMembersResp } from '../interface/members';

async function login(data: ILoginData): Promise<ILoginUserResp> {
	return requestMaker({
		type: 'POST',
		route: endpoints.AUTH,
		data,
	});
}

async function getLoggedInUser(): Promise<ILoggedInUserResp> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'GET',
		route: endpoints.AUTH,
	});
}

async function getDashboardAnalytics(): Promise<IDashboardAnalyticsResp> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'GET',
		route: endpoints.DASHBOARD_ANALYTICS,
	});
}

async function getBookingRequests(query?: IBookingRequestsQuery): Promise<IBookingRequestsResp> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'GET',
		route: endpoints.BOOKING_REQUEST,
		queryParams: query
	});
}

async function getTopBooking(query?: ITopBookingQuery): Promise<ITopBookingResp> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'GET',
		route: endpoints.TOP_BOOKING,
		queryParams: query
	});
}

async function getAdverts(
	query?: IQuery
): Promise<IGetAdvertsResp> {
	return requestMaker({
		type: 'GET',
		route: endpoints.ADVERTS,
		queryParams: query,
	});
}

async function deleteAdvert(
	advertId: string
): Promise<IGetAdvertsResp> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'DELETE',
		route: `${endpoints.ADVERTS}/${advertId}`,
	});
}

async function addAdvert(
	data: FormData
): Promise<INetworkResponse> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'POST',
		route: endpoints.ADVERTS,
		data
	});
}

async function editAdvert(
	advertId: string, data: FormData
): Promise<INetworkResponse> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'PATCH',
		route: `${endpoints.ADVERTS}/${advertId}`,
		data
	});
}

async function getDocumentUrl(key: string): Promise<IDocumentUrlResp> {
	return requestMaker({
		type: 'GET',
		route: endpoints.GET_DOCUMENT_LINK + '/' + key.replaceAll('/', '%2F'),
	});
}

async function getBookings(query?: IQuery): Promise<IGetBookingsResp> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'GET',
		route: endpoints.BOOKINGS,
		queryParams: query
	});
}

async function getBookingsSlots(query: IGEtBookingSlotsQuery): Promise<IGetBookingsSlotsResp> {
	return requestMaker({
		type: 'GET',
		route: endpoints.BOOKINGS + '/slots',
		queryParams: query
	});
}

async function addBooking(data: IAddBookingData): Promise<INetworkResponse> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'POST',
		route: endpoints.BOOKINGS,
		data
	});
}

async function updateBooking(bookingId: string, data: IUpdateBookingData): Promise<INetworkResponse> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'PATCH',
		route: `${endpoints.BOOKINGS}/${bookingId}`,
		data
	});
}

async function getEnquiries(query?: IQuery): Promise<IGetEnqiriesResp> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'GET',
		route: endpoints.ENQUIRIES,
		queryParams: query
	});
}

async function getMembers(query?: IQuery): Promise<IGetMembersResp> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'GET',
		route: endpoints.MEMBERS,
		queryParams: query
	});
}

async function addMembers(data: FormData): Promise<INetworkResponse> {
	return requestMaker({
		headers: { Authorization: `${storage.getItem('accessToken')}` },
		type: 'POST',
		route: endpoints.MEMBERS,
		data
	});
}

async function requestResetToken(data: { email: string }): Promise<INetworkResponse> {
	return requestMaker({
		data,
		type: 'POST',
		route: endpoints.RESET_PASSWORD,
	});
}

async function resetPassword(data: {
	passwordConfirmation: string
	password: string
	token: string
}): Promise<INetworkResponse> {
	return requestMaker({
		data,
		type: 'PUT',
		route: endpoints.RESET_PASSWORD,
	});
}

async function verifyResetToken(token: string): Promise<INetworkResponse> {
	return requestMaker({
		type: 'GET',
		route: `${endpoints.RESET_PASSWORD}/${token}`,
	});
}

export default {
	login,
	getAdverts,
	getDocumentUrl,
	getDashboardAnalytics,
	getBookingRequests,
	getTopBooking,
	getLoggedInUser,
	getBookings,
	getEnquiries,
	getMembers,
	deleteAdvert,
	addAdvert,
	addMembers,
	addBooking,
	updateBooking,
	editAdvert,
	requestResetToken,
	verifyResetToken,
	resetPassword,
	getBookingsSlots
}
