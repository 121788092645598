import React, { type FC } from 'react'
import { Modal } from 'react-bootstrap'
import styles from '../enquiries.module.css'
import type { IEnquiry } from '../../../interface/enquiries'
import moment from 'moment'

interface ViewInquiryModalProps {
  viewEnquiry: boolean
  toggleViewEnquiry: () => void
  selected: IEnquiry | undefined
}

const ViewInquiryModal: FC<ViewInquiryModalProps> = ({
  viewEnquiry,
  toggleViewEnquiry,
  selected,
}) => {
  return (
    <Modal
      show={viewEnquiry}
      onHide={toggleViewEnquiry}
      centered
      dialogClassName={styles.modal_dialog}
    >
      <Modal.Header closeButton className="border-bottom border-2">
        <Modal.Title className={styles.modal_title}>
          Customer Inquiry Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="mt-2 d-flex justify-content-between align-items-center p-2 border-bottom border-2 gap-1 flex-wrap">
          <div className={styles.modal_flex_divs}>
            <p className={styles.body_header}>Member Name</p>
            <p className={styles.body_text}>{selected?.name}</p>
          </div>
          <div className={styles.modal_flex_divs}>
            <p className={styles.body_header}>Member Email</p>
            <p className={styles.body_text}>{selected?.email}</p>
          </div>
        </section>
        <section className="mt-4 d-flex justify-content-between align-items-center p-2 border-bottom border-2 gap-1 flex-wrap">
          <div className={styles.modal_flex_divs}>
            <p className={styles.body_header}>Member Phone Number</p>
            <p className={styles.body_text}>
              {selected?.phone ?? 'Not available'}
            </p>
          </div>
          <div className={styles.modal_flex_divs}>
            <p className={styles.body_header}>Date</p>
            <p className={styles.body_text}>
              {moment(selected?.createdAt).format('Do, MMM YYYY')}
            </p>
          </div>
        </section>
        <section className="mt-4 p-2 border-bottom border-2">
          <div>
            <p className={styles.body_header}>Customer Inquiry</p>
            <p className={styles.body_text}>{selected?.comment}</p>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default ViewInquiryModal
