// // import { AnyAction } from 'redux';
// import { ThunkAction } from 'redux-thunk';
import type { ActionInterface } from '../IAction';
import type { AppDispatch } from '../index';
import api from '../../network/api';
import type { IUser } from '../../interface/auth';
import * as storage from '../../util/localStorage';
import { toast } from 'react-toastify';

export const USER_REQUEST = 'AUTHORIZATION/REQUEST';
export const USER_SUCCESS = 'AUTHORIZATION/SUCCESS';
export const USER_FAILURE = 'AUTHORIZATION/FAILURE';

const userRequest = (): ActionInterface<unknown> => ({
  type: USER_REQUEST,
});

const userSuccess = (payload: IUser): ActionInterface<unknown> => ({
  type: USER_SUCCESS,
  payload,
});

const userFailure = (): ActionInterface<unknown> => ({
  type: USER_FAILURE,
});

export function getCurrentUser(): any {
  return async (dispatch: AppDispatch) => {
    dispatch(userRequest());
    try {
      const { data } = await api.getLoggedInUser();
      dispatch(userSuccess(data));
    } catch (error: any) {
      storage.clear();
      window.location.href = '/login'
      dispatch(userFailure());
      toast.error(error?.message ?? 'Something went wrong')
    }
  };
}
