import { toast } from 'react-toastify';
import type { IError } from '../interface/error';

export const getError = async (data: Response): Promise<Error> => {
  if (data.text) {
    const dd = await data.text();
    if (dd) {
      const error: IError = JSON.parse(dd);
      if (error?.name === 'TokenExpiredError') {
        localStorage.clear()
        window.location.href = '/login'
      }
      throw {
        name: error.name,
        code: error.code,
        message: error.message
      }
    } else {
      throw {
        message: 'An error occurred, please try again later.',
      };
    }
  } else {
    throw {
      message: 'An error occurred, please try again later.',
    };
  }
};

export const handleError = (error: any): void => {
  toast.error(error?.message ?? 'Something went wrong')
}
