import React, { useState, type FC, type ChangeEvent } from 'react'
import Logo from '../../assets/svgs/HPlayFullBlack.svg'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import styles from './login.module.css'
import { combineClassName } from '../../util/utilMethods'
import Icon from '../../Components/Icon'
import api from '../../network/api'
import * as storage from '../../util/localStorage'
import validator from 'validator'
import { handleError } from '../../util/error'

interface IAuth {
  email: string
  password: string
}

const authInitialState = {
  email: '',
  password: '',
}

const Login: FC = () => {
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [auth, setAuth] = useState<IAuth>(authInitialState)

  const togglePassword = (): void => {
    setShowPassword(!showPassword)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setAuth({ ...auth, [e.target.name]: e.target.value })
  }

  const onSubmit = async (): Promise<void> => {
    setLoading(true)
    try {
      const resp = await api.login(auth)
      storage.setItem('accessToken', resp.data.accessToken)
      storage.setItem('refreshToken', resp.data.refreshToken)
      navigate('/', { replace: true })
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }

  return (
    <main className={styles.wrapper}>
      <div>
        <img src={Logo} alt="logo" />
      </div>
      <h1 className={styles.heading}>Login to your account</h1>
      <div>
        <FloatingLabel label="Email address" className="mb-4">
          <Form.Control
            type="email"
            placeholder="Enter your email"
            onChange={handleChange}
            value={auth.email}
            name="email"
          />
        </FloatingLabel>
        <FloatingLabel
          label="Password"
          className={combineClassName('mb-2', styles.password_input)}
        >
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter your password"
            onChange={handleChange}
            value={auth.password}
            name="password"
          />
          <div className={styles.password_icon} onClick={togglePassword}>
            <Icon name={showPassword ? 'hide_password' : 'show_password'} />
          </div>
        </FloatingLabel>
      </div>
      <div
        className={combineClassName(
          'd-flex justify-content-end',
          styles.forgot_password
        )}
      >
        <Link
          to="/reset-password"
          className="text-primary text-decoration-none"
        >
          Forgot Password?
        </Link>
      </div>
      <Button
        className="w-100"
        size="lg"
        disabled={loading || !validator.isEmail(auth.email) || !auth.password}
        onClick={(): any => {
          onSubmit()
        }}
      >
        {loading ? 'Please wait...' : 'Login'}
      </Button>
    </main>
  )
}

export default Login
