import React, { useState, type FC } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import styles from '../bookings.module.css'
import { useDispatch } from 'react-redux'
import api from '../../../network/api'
import { getAllBookings } from '../../../redux/bookings/actions'
import { handleError } from '../../../util/error'

enum PaymentStatusEnum {
  unPaid = 'unpaid',
  paid = 'paid',
}

interface StatusColor {
  color: string
  background: string
}

const statusColor = (status: string): StatusColor => {
  switch (status) {
    case PaymentStatusEnum.paid:
      return {
        color: '#188E47',
        background: '#E8F4ED',
      }
    default:
      return {
        color: '#1C201F',
        background: '#E9E9E9',
      }
  }
}

interface IPaymentStatusProps {
  status: string
  id: string
}

const PaymentStatus: FC<IPaymentStatusProps> = ({ status, id }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const handleUpdate = async (selected: PaymentStatusEnum): Promise<void> => {
    setLoading(true)
    try {
      await api.updateBooking(id, { paymentStatus: selected })
      dispatch(getAllBookings())
    } catch (error: any) {
      handleError(error)
    }
    setLoading(false)
  }
  return (
    <Dropdown>
      <Dropdown.Toggle variant="custom" id="dropdown-basic">
        <span className={styles.booking_status} style={statusColor(status)}>
          {loading ? <Spinner variant="primary" /> : status}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.values(PaymentStatusEnum).map((item, i) => (
          <Dropdown.Item
            key={i}
            onClick={(): any => {
              handleUpdate(item)
            }}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default PaymentStatus
