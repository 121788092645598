import type { ActionInterface } from '../IAction';
import type { AppDispatch } from '../index';
import api from '../../network/api';
import type { IMeta, IQuery } from '../../interface/network';
import type { IMember } from '../../interface/members';

export const MEMBERS_REQUEST = 'MEMEBER/REQUEST';
export const MEMBERS_SUCCESS = 'MEMEBER/SUCCESS';
export const MEMBERS_SUCCESS_META = 'MEMEBER/SUCCESS/META';
export const MEMBERS_FAILURE = 'MEMEBER/FAILURE';

const membersRequest = (): ActionInterface<unknown> => ({
  type: MEMBERS_REQUEST,
});

const membersSuccess = (payload: IMember[]): ActionInterface<unknown> => ({
  type: MEMBERS_SUCCESS,
  payload,
});

const membersSuccessMeta = (payload: IMeta): ActionInterface<unknown> => ({
  type: MEMBERS_SUCCESS_META,
  payload,
});

const membersFailure = (): ActionInterface<unknown> => ({
  type: MEMBERS_FAILURE,
});

export function getAllMembers(queries?: IQuery): any {
  return async (dispatch: AppDispatch) => {
    dispatch(membersRequest());
    try {
      const { data, meta } = await api.getMembers(queries);
      dispatch(membersSuccess(data));
      dispatch((membersSuccessMeta(meta)))
    } catch (error: any) {
      dispatch(membersFailure());
    }
  };
}
