/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, type ReactNode } from 'react'

interface IPaginationData {
  count: number
  paginationLimit: number[]
}
interface ITableContext {
  pageSettings: IPaginationData
  setPage: (data: IPaginationData) => void
}

export const TableContext = React.createContext<ITableContext>({
  pageSettings: {
    count: 1,
    paginationLimit: [0, 5],
  },
  setPage: (data: IPaginationData) => {},
})

export const TableProvider = ({ children }: { children: ReactNode }) => {
  const [pageSettings, setPageSettings] = useState<IPaginationData>({
    count: 1,
    paginationLimit: [0, 5],
  })

  const setPage = (data: IPaginationData): void => {
    setPageSettings(data)
  }

  const contextValue = {
    pageSettings,
    setPage,
  }

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  )
}
