import React, { type FC } from 'react'
import { Modal } from 'react-bootstrap'
import Select2 from '../Select2'

interface ActivityModalProps {
  selectActivity: boolean
  toggleSelectActivity: () => any
  handleChange: (a?: any) => any
}

const ActivityModal: FC<ActivityModalProps> = ({
  selectActivity,
  toggleSelectActivity,
  handleChange,
}) => {
  return (
    <Modal centered show={selectActivity} onHide={toggleSelectActivity}>
      <Modal.Header closeButton className="modal_special_header fs-5">
        Activity
      </Modal.Header>
      <Modal.Body>
        <Select2
          leaveOptionCase
          label="Select Activity"
          labelDescription="Select"
          values={['swimming_pool', 'football_pitch']}
          options={['Swimming Pool', 'Football Pitch']}
          onChange={(value) => {
            handleChange(value)
            toggleSelectActivity()
          }}
        />
      </Modal.Body>
    </Modal>
  )
}

export default ActivityModal
