import type { ActionInterface } from '../IAction';
import {
    SHOW_EXPORT_MODAL, CLOSE_EXPORT_MODAL, EXPORT_REQUEST
} from './actions';

export interface IExportInitialState {
    loading: boolean
    showModal: boolean
    func: any
}

const initialState = {
    loading: false,
    showModal: false,
    func: null,
};

const reducer = (state = initialState, action: ActionInterface<unknown>): IExportInitialState => {
    switch (action.type) {
        case SHOW_EXPORT_MODAL:
            return {
                loading: false,
                showModal: true,
                func: action.payload
            };
        case CLOSE_EXPORT_MODAL:
            return {
                loading: false,
                showModal: false,
                func: null
            };
        case EXPORT_REQUEST:
            return {
                ...state,
                loading: true,
                showModal: true,
            };
        default:
            return state;
    }
};

export default reducer;
