import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

import mobileMenu from './mobile-menu/reducer'
import bookings from './bookings/reducer'
import enquiries from './enquiries/reducer'
import currentUser from './currentUser/reducer'
import members from './members/reducer'
import adverts from './adverts/reducer'
import exportList from './export-list/reducer'
import sideBar from './side-bar/reducer'
import { SHOW_EXPORT_MODAL } from './export-list/actions'

const reducer = {
    mobileMenu,
    bookings,
    enquiries,
    currentUser,
    members,
    adverts,
    exportList,
    sideBar
};

const middleware = [thunk];

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // Ignore these action types
            ignoredActions: [SHOW_EXPORT_MODAL],
            // Ignore these field paths in all actions
            // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
            // Ignore these paths in the state
            ignoredPaths: ['exportList.func'],
        },
    }).concat(middleware),
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
