/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, {
  cloneElement,
  type CSSProperties,
  type FC,
  type ReactElement,
  type TdHTMLAttributes,
  type ThHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import { combineClassName } from '../../util/utilMethods'
import './styles.scss'
import UseTableSettings from '../../hooks/useTableSettings'

interface TableProps {
  /**
   * The table header
   * @note wrap ReactElement inside ```th```
   */
  headers: Array<string | ReactElement<ThHTMLAttributes<unknown>>>

  /**
   * table content
   * @note wrap ReactElement inside ```td```
   */
  data: Array<Array<string | ReactElement<TdHTMLAttributes<unknown>>>>

  /**
   * total data
   */
  // totalPages: number;

  numberOfPages: number

  limit: number

  totalResult: number

  callBack?: (data?: any) => any

  currentPage: number

  /**
   * Table title
   */
  title?: string

  /**
   * Css style
   */
  style?: CSSProperties

  /**
   * Css class name
   */
  className?: string

  hidePagination?: boolean

  hideSearchBar?: boolean

  titleRhs?: any

  search?: boolean
}

const Table: FC<TableProps> = ({
  data,
  headers,
  totalResult,
  limit,
  numberOfPages,
  callBack,
  currentPage,
  title,
  style,
  hidePagination,
  hideSearchBar,
  titleRhs,
  className,
  search,
}) => {
  const [dataPerPage, setDataPerPage] = useState(limit)
  const [dataPerPageInput, setDataPerPageInput] = useState(limit)
  const [rowData, setRowData] = useState(data)
  const [pages, setPages] = useState<Array<number>>([])

  const debounceIdRef = useRef<any>(null)

  const { setPage, pageSettings } = UseTableSettings()

  useEffect(() => {
    if (numberOfPages) {
      const pages = []
      for (let i = 1; i <= numberOfPages; i++) {
        pages.push(i)
      }
      setPages(pages)
    }
    return () => {
      setPage({ count: 1, paginationLimit: [0, 5] })
    }
  }, [numberOfPages, setPage])

  useEffect(() => {
    if (search && numberOfPages) {
      const searchPages = []
      for (let i = 1; i <= numberOfPages; i++) {
        searchPages.push(i)
      }
      setPages(searchPages)
    }
  }, [search, numberOfPages])

  useEffect(() => {
    if (data.length) {
      setRowData(data)
    } else {
      setRowData([])
    }
  }, [data])

  const onSearch = (value: string) => {
    if (value && value.length >= 4) {
      if (callBack) {
        callBack({ search: value })
        setPage({ count: 1, paginationLimit: [0, limit] })
      }
    } else if (!value.length) {
      if (callBack) callBack()
    }
  }
  const onDataPerPageChange = (value: string) => {
    setDataPerPageInput(+value || 0)

    if (debounceIdRef.current) {
      clearInterval(debounceIdRef.current)
    }
    debounceIdRef.current = setTimeout(() => {
      if (callBack) callBack({ limit: +value || 10 })
      if (value) {
        setDataPerPage(+value)
      } else {
        setDataPerPage(1)
      }
    }, 1000)
  }

  const nextPage = async () => {
    if (!search) {
      if (callBack && currentPage !== numberOfPages) {
        const next = currentPage + 1
        await callBack({ limit, page: +next - 1 })
        const count = pageSettings.count + 1
        if (count === 6) {
          setPage({
            paginationLimit: [
              pageSettings.paginationLimit[0] + 5,
              pageSettings.paginationLimit[1] + 5,
            ],
            count: 1,
          })
        } else {
          setPage({ ...pageSettings, count })
        }
      }
    } else {
      if (pageSettings.count !== numberOfPages) {
        setPage({
          paginationLimit: [
            pageSettings.paginationLimit[0] + limit,
            pageSettings.paginationLimit[1] + limit,
          ],
          count: pageSettings.count + 1,
        })
      }
    }
  }

  const prevPage = async () => {
    if (!search) {
      if (callBack && currentPage === 2) {
        await callBack({ limit, page: 0 })
        setPage({
          count: 1,
          paginationLimit: [0, 5],
        })
        return
      }
      if (callBack && currentPage !== 1) {
        const prev = currentPage - 1
        await callBack({ limit, page: +prev - 1 })
        const count = pageSettings.count - 1
        if (count === 0) {
          setPage({
            count: 5,
            paginationLimit: [
              pageSettings.paginationLimit[0] - 5,
              pageSettings.paginationLimit[1] - 5,
            ],
          })
        } else {
          setPage({ ...pageSettings, count })
        }
      }
    } else {
      if (pageSettings.count !== 1) {
        setPage({
          paginationLimit: [
            pageSettings.paginationLimit[0] - limit,
            pageSettings.paginationLimit[1] - limit,
          ],
          count: pageSettings.count - 1,
        })
      }
    }
  }

  const goto = async (page: number) => {
    if (!search) {
      if (callBack) {
        await callBack({ limit, page: page - 1 })
        const diff = page - currentPage
        let count = 0
        count = pageSettings.count + diff
        setPage({ ...pageSettings, count })
      }
    }
  }

  // useEffect(() => {
  // 	setNumberOfPages(Math.ceil(totalPages / dataPerPage));
  // }, [ totalPages, dataPerPage]);

  return (
    <div className={combineClassName('table', className)} style={style}>
      {title ? (
        <div className="titleWrapper">
          <div className="title">{title}</div>
          <div className="title_rhs">{titleRhs}</div>
        </div>
      ) : null}
      {hideSearchBar ? null : (
        <div className="table_header">
          <div>
            <span>Show</span>
            <input
              type="text"
              value={dataPerPageInput}
              onChange={(e) => {
                onDataPerPageChange(e.target.value)
              }}
              className="entries_box"
            />
            <span>entries</span>
          </div>
          <input
            className="search_box"
            onChange={(e) => {
              onSearch(e.target.value)
            }}
            placeholder="Search.."
          />
        </div>
      )}
      <div className="table_wrapper">
        <table>
          <tbody>
            <tr>
              {headers.map((Elem, index) => {
                return typeof Elem === 'string' ? (
                  <th key={index}>{Elem}</th>
                ) : (
                  cloneElement(Elem, { key: index })
                )
              })}
            </tr>
            {!search ? (
              <>
                {rowData.map((elem, index) => {
                  return (
                    <tr key={index}>
                      {elem.map((item, _index) => {
                        return typeof item === 'string' ? (
                          <td key={_index.toString().concat(index + '')}>
                            {item}
                          </td>
                        ) : (
                          cloneElement(item, {
                            key: _index.toString().concat(index + ''),
                          })
                        )
                      })}
                    </tr>
                  )
                })}
              </>
            ) : (
              <>
                {rowData
                  .slice(
                    pageSettings.paginationLimit[0],
                    pageSettings.paginationLimit[1]
                  )
                  .map((elem, index) => {
                    return (
                      <tr key={index}>
                        {elem.map((item, _index) => {
                          return typeof item === 'string' ? (
                            <td key={_index.toString().concat(index + '')}>
                              {item}
                            </td>
                          ) : (
                            cloneElement(item, {
                              key: _index.toString().concat(index + ''),
                            })
                          )
                        })}
                      </tr>
                    )
                  })}
              </>
            )}
          </tbody>
        </table>
      </div>
      {hidePagination ? null : search ? (
        <div className="table_footer">
          {' '}
          <div>
            <p>
              Showing {pageSettings.count * dataPerPage + 1 - dataPerPage} -{' '}
              {pageSettings.count * dataPerPage} of {totalResult} entries{' '}
            </p>
          </div>
          <ul className="pagination">
            <li className="pagination_button" onClick={prevPage}>
              Previous
            </li>
            {pages.map((pag, index) => {
              return (
                <li
                  key={index}
                  onClick={async () => goto(pag)}
                  className={combineClassName(
                    'pagination_button',
                    pag === pageSettings.count ? 'pagination_button_active' : ''
                  )}
                >
                  {pag}
                </li>
              )
            })}
            {currentPage !== numberOfPages && (
              <li className={combineClassName('pagination_button')}>...</li>
            )}
            <li className="pagination_button" onClick={nextPage}>
              Next
            </li>
          </ul>
        </div>
      ) : (
        // normal search
        <div className="table_footer">
          <ul className="pagination">
            <li
              style={{ marginRight: '2.75rem' }}
              className="pagination_action"
              onClick={prevPage}
            >
              Previous
            </li>
            {pages
              .slice(
                pageSettings.paginationLimit[0],
                pageSettings.paginationLimit[1]
              )
              .map((pag, index) => {
                return (
                  <li
                    key={index}
                    onClick={async () => goto(pag)}
                    style={{ marginRight: '1.25rem' }}
                    className={combineClassName(
                      'pagination_button',
                      pag === currentPage ? 'pagination_button_active' : ''
                    )}
                  >
                    {pag}
                  </li>
                )
              })}
            {currentPage !== numberOfPages && (
              <li className={combineClassName('pagination_button')}>...</li>
            )}
            <li
              style={{ marginLeft: '1.50rem' }}
              className="pagination_action"
              onClick={nextPage}
            >
              Next
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default Table
