import type { ActionInterface } from '../IAction';
import type { AppDispatch } from '../index';
import api from '../../network/api';
import type { IMeta, IQuery } from '../../interface/network';
import type { IAdvert } from '../../interface/adverts';
export const ADVERTS_REQUEST = 'ADVERTS/REQUEST';
export const ADVERTS_SUCCESS = 'ADVERTS/SUCCESS';
export const ADVERTS_SUCCESS_META = 'ADVERTS/SUCCESS/META';
export const ADVERTS_FAILURE = 'ADVERTS/FAILURE';

const advertsRequest = (): ActionInterface<unknown> => ({
  type: ADVERTS_REQUEST,
});

const advertsSuccess = (payload: IAdvert[]): ActionInterface<unknown> => ({
  type: ADVERTS_SUCCESS,
  payload,
});

const advertsSuccessMeta = (payload: IMeta): ActionInterface<unknown> => ({
  type: ADVERTS_SUCCESS_META,
  payload,
});

const advertsFailure = (): ActionInterface<unknown> => ({
  type: ADVERTS_FAILURE,
});

export function getAllAdverts(queries?: IQuery): any {
  return async (dispatch: AppDispatch) => {
    dispatch(advertsRequest());
    try {
      const { data, meta } = await api.getAdverts(queries);
      dispatch(advertsSuccess(data));
      dispatch((advertsSuccessMeta(meta)))
    } catch (error: any) {
      dispatch(advertsFailure());
    }
  };
}
